import { Job, JobState, JobType } from "../../generated_protos/admin/admin_job_pb";

export const getActiveReplaceFilterJobs = (jobs: Job.AsObject[]) => {
  return jobs
    .filter((job) => {
      return (
        job.type === JobType.JOB__CORPUS_REPLACE_FILTER_ATTRS &&
        [
          JobState.JOB_STATE__STARTED,
          JobState.JOB_STATE__TRANSIENT_FAILURE_RETRY_IMMINENT,
          JobState.JOB_STATE__QUEUED
        ].includes(job.state)
      );
    })
    .sort((a, b) => {
      switch (a.state) {
        case JobState.JOB_STATE__STARTED:
          return -1;

        case JobState.JOB_STATE__QUEUED:
          return 1;

        case JobState.JOB_STATE__TRANSIENT_FAILURE_RETRY_IMMINENT:
          if (b.state === JobState.JOB_STATE__STARTED) {
            return 1;
          }

          if (b.state === JobState.JOB_STATE__QUEUED) {
            return -1;
          }
      }

      return 0;
    });
};
