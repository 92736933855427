import { Job } from "../../generated_protos/admin/admin_job_pb";

/**
When you request jobs for a corpus, you'll get back those that represent
updates to jobs already stored in the context plus new jobs that aren't
yet stored in the context. Jobs that are stored in the context for this
corpus but aren't returned in the request are considered completed and
can be removed.
**/

export const updateJobs = (currentJobs: Job.AsObject[], updatedJobs: Job.AsObject[], corpusIds: number[]) => {
  const netNewJobs = updatedJobs.concat();

  const syncedJobs = currentJobs.reduce((acc, currentJob) => {
    // If the job affects other corpora too, then we should retain it because
    // the work for those other corpora might not be done yet.
    const isJobForMultipleCorpora = currentJob.corpusIdList.length > 1;
    if (isJobForMultipleCorpora) {
      acc.push(currentJob);
      return acc;
    }

    // If it's a job unrelated to this corpus then we should retain it.
    const isJobRelevant = corpusIds.includes(currentJob.corpusIdList[0]);
    if (!isJobRelevant) {
      acc.push(currentJob);
      return acc;
    }

    // If the job is associated with the corpus we're interested in,
    // we can update it.
    const index = netNewJobs.findIndex((updatedJob) => updatedJob.id === currentJob.id);
    // NOTE: Job IDs aren't static. The same job's ID will change each time you
    // fetch it from the server. So we can't use the ID to match jobs. For this reason,
    // isJobUpdated will always be false, until we change this behavior on the server.
    const isJobUpdated = index !== -1;

    // If we get back a job that already have, it's an update and we'll include it.
    // If we can't find an update for the job then it's completed. We'll exclude it.
    if (isJobUpdated) {
      acc.push(netNewJobs[index]);
      // As we remove updates from the requested jobs, we'll eventually leave
      // net new jobs remaining.
      netNewJobs.splice(index, 1);
    }

    return acc;
  }, [] as Job.AsObject[]);

  const result = syncedJobs.concat(netNewJobs);
  return result;
};
