import { snowUrl } from "../../backendConfig";
import { SurveyQuestionId } from "./types";

type SurveyPayload = {
  anonymous_id: string;
  poll_id: string;
  customer_id: string;

  analytics_auth_token: string;
  source: string;
  questions: Array<{
    question_id: SurveyQuestionId;
    question_text: string;
    answers: Array<{
      answer_id: string;
      answer_text?: string;
    }>;
  }>;
};

export const submitSurvey = async ({
  jwt,
  customerId,
  payload
}: {
  jwt: string;
  customerId: string;
  payload: SurveyPayload;
}) => {
  // Consumer shouldn't care about the response so no need to return anything.
  window.fetch(`${snowUrl}/submit_poll`, {
    method: "POST",
    headers: new Headers({
      "customer-id": customerId,
      authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json"
    }),
    body: JSON.stringify(payload)
  });
};
