import Analytics from "analytics";
import googleTagManager from "@analytics/google-tag-manager";
import { ANONYMOUS_ID_COOKIE_NAME } from "../../constants";
import { Identity, Page } from "./snowTypes";
import { setAnonymousId } from "../UserContext";
import { IS_PROD, snowUrl } from "../../backendConfig";

const snowEndpoint = `${snowUrl}/ui_event`;
const VECTARA_UTM = "vectara_utm";

const getCookie = (name: string) => {
  const matches = document.cookie.match(
    new RegExp("(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1") + "=([^;]*)")
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const generateIdentityFromPayload = (payload: any): Identity => {
  // Load utm from localstorage
  const utm = localStorage.getItem(VECTARA_UTM);
  const { meta, properties, anonymousId } = payload;

  const persistedAnonymousId = getCookie(ANONYMOUS_ID_COOKIE_NAME);

  const domain = window.location.hostname === "localhost" ? "localhost" : IS_PROD ? "vectara.com" : "vectara.dev";

  if (!persistedAnonymousId) {
    // Expire the cookie in 1 year.
    const cookie = `${ANONYMOUS_ID_COOKIE_NAME}=${anonymousId}; path=/; max-age=31536000; domain=${domain}`;
    document.cookie = cookie;
  }

  const currentAnonymousId = persistedAnonymousId ?? anonymousId;
  setAnonymousId(currentAnonymousId);

  const identityData = {
    anonymousId: currentAnonymousId ?? null,
    timestamp: meta.ts,
    utm: utm ? JSON.parse(utm) : null,
    userData: {
      userSub: properties.userId ?? null,
      email: properties.email ?? null,
      customerId: properties.customerId ?? null
    }
  };
  if (utm) {
    localStorage.removeItem(VECTARA_UTM);
  }
  return identityData;
};

const generatePageData = (payload: any): Page => {
  const { properties } = payload;
  return {
    identity: generateIdentityFromPayload(payload),
    path: properties.path,
    title: properties.title,
    referrer: properties.referrer ?? null,
    search: properties.search ?? null,
    url: properties.url ?? null,
    width: properties.width,
    height: properties.height,
    application: "CONSOLE",
    locale: navigator.language,
    agent: navigator.userAgent,
    type: properties.type ?? "PAGE"
  };
};

const snowApp = {
  name: "snow-plugin",
  campaign: ({ payload }: any) => {
    // Store utm in localstorage
    localStorage.setItem(VECTARA_UTM, JSON.stringify(payload.campaign));
  },
  page: ({ payload }: any) => {
    // Send data to snow endpoint
    const pageData = generatePageData(payload);
    fetch(snowEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(pageData)
    });
  }
};

/* Initialize analytics & load plugins */
export const analytics = Analytics({
  app: "vectara-console",
  plugins: [
    snowApp,
    googleTagManager({
      containerId: "GTM-N9LHSN3"
    })
  ]
});
