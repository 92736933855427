import { AUTH_MECHANISM, GOOGLE_SSO_ACTION, CUSTOMER_ID, AuthMechanism, GoogleSsoAction, SESSION } from "./keys";

export const getAuthMechanism = (): AuthMechanism => {
  return localStorage.getItem(AUTH_MECHANISM) as AuthMechanism;
};

export const setAuthMechanism = (authMechanism: AuthMechanism) => {
  if (!authMechanism) return;
  localStorage.setItem(AUTH_MECHANISM, authMechanism);
};

export const removeAuthMechanism = () => {
  localStorage.removeItem(AUTH_MECHANISM);
};

export const getGoogleSsoAction = (): GoogleSsoAction => {
  return localStorage.getItem(GOOGLE_SSO_ACTION) as GoogleSsoAction;
};

export const setGoogleSsoAction = (googleSsoAction: GoogleSsoAction) => {
  if (!googleSsoAction) return;
  localStorage.setItem(GOOGLE_SSO_ACTION, googleSsoAction);
};

export const removeGoogleSsoAction = () => {
  localStorage.removeItem(GOOGLE_SSO_ACTION);
};

export const getCustomerId = (): string | null => {
  return localStorage.getItem(CUSTOMER_ID);
};

export const setCustomerId = (customerId: string) => {
  localStorage.setItem(CUSTOMER_ID, customerId);
};

export const getSession = (): {
  expiresAt: Date;
  jwt: string;
  email: string;
  username: string;
  sessionId: string;
  customerId: number;
} | null => {
  const sessionStr = localStorage.getItem(SESSION);
  if (!sessionStr) {
    return null;
  }
  return JSON.parse(localStorage.getItem(SESSION) ?? "{}");
};

export const setSession = (session: unknown) => {
  localStorage.setItem(SESSION, JSON.stringify(session));
};

export const removeOryItems = () => {
  localStorage.removeItem(SESSION);
  localStorage.removeItem(CUSTOMER_ID);
};
