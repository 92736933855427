import { get } from "lodash";
import { ReadUsageStatsResponse } from "../../generated_protos/admin/admin_stats_pb";
import { PricingPlan } from "../../types/billing";
import { dataStoragePrimitives } from "../../utils/jargon";

// Casting to number and then casting to string will trim off
// trailing zeros after the decimal point.
export const bytesToMb = (bytes: number) => humanizeNumber(Number((bytes / 1048576).toFixed(2)));
export const humanizeNumber = (value: number) => value.toLocaleString("en-US");

const resourceTypes = [
  "dataStorage",
  "dataIngest",
  "queries",
  "generativeRequests",
  "dataStoragePrimitives",
  "users"
] as const;

export type ResourceType = (typeof resourceTypes)[number];

type ResourceConfig = {
  usageStatsAmountPath?: string;
  usageStatsAmountProvider?: (usageStats: ReadUsageStatsResponse.AsObject) => number;
  usageStatsBaseLimitPath?: string;
  planBaseLimitPath?: string;
  planBundleIncrementPath?: string;
  humanize: (value: number) => string;
  unit: string;
};

export type ResourceAnalysis = {
  bundleIncrement?: string;
  amount?: string;
  baseLimit?: string;
  percentage: number;
  unit: string;
  isOverLimit: boolean;
  extra?: {
    limitIncrease: string;
    totalLimit: string;
  };
};

const resourceToConfigMap: Record<ResourceType, ResourceConfig> = {
  dataStorage: {
    usageStatsAmountPath: "storageStats.actualCount",
    usageStatsBaseLimitPath: "storageStats.allowedCount",
    planBundleIncrementPath: "queryStorageIngestBundle.storageBytes",
    humanize: bytesToMb,
    unit: "MB"
  },
  dataIngest: {
    usageStatsAmountProvider: ({ indexStatsList }: ReadUsageStatsResponse.AsObject) => {
      return indexStatsList?.map((x) => x.actualCount).reduce((prev, next) => prev + next, 0);
    },
    planBaseLimitPath: "includedIndexing.amount",
    planBundleIncrementPath: "queryStorageIngestBundle.ingestBytes",
    humanize: bytesToMb,
    unit: "MB"
  },
  queries: {
    usageStatsAmountProvider: ({ queryStatsList }: ReadUsageStatsResponse.AsObject) => {
      return queryStatsList?.map((x) => x.actualCount).reduce((prev, next) => prev + next, 0);
    },
    planBaseLimitPath: "includedQueries.amount",
    planBundleIncrementPath: "queryStorageIngestBundle.queries",
    humanize: humanizeNumber,
    unit: "queries"
  },
  generativeRequests: {
    usageStatsAmountPath: "generativeStats.actualCount",
    planBaseLimitPath: "includedSummarizations.amount",
    planBundleIncrementPath: "queryStorageIngestBundle.summarizations",
    humanize: humanizeNumber,
    unit: "requests"
  },
  dataStoragePrimitives: {
    usageStatsAmountPath: "corporaStats.actualCount",
    usageStatsBaseLimitPath: "corporaStats.allowedCount",
    humanize: humanizeNumber,
    unit: dataStoragePrimitives
  },
  users: {
    usageStatsAmountPath: "usersStats.actualCount",
    usageStatsBaseLimitPath: "usersStats.allowedCount",
    humanize: humanizeNumber,
    unit: "users"
  }
};

export const analyzeUsage = (
  bundlesQuantity: number,
  currentPlan?: PricingPlan,
  usageStats?: ReadUsageStatsResponse.AsObject
) => {
  if (!currentPlan || !usageStats) return undefined;

  return resourceTypes.reduce((acc, resourceType) => {
    const {
      usageStatsAmountPath,
      usageStatsAmountProvider,
      usageStatsBaseLimitPath,
      planBaseLimitPath,
      planBundleIncrementPath,
      humanize,
      unit
    } = resourceToConfigMap[resourceType];

    // Sometimes the back-end takes awhile to initialize these values, so we can safely default to 0 if they're undefined.
    const amount =
      (usageStatsAmountPath ? get(usageStats, usageStatsAmountPath) : usageStatsAmountProvider!(usageStats)) ?? 0;

    const baseLimit = (
      usageStatsBaseLimitPath ? get(usageStats, usageStatsBaseLimitPath) : get(currentPlan, planBaseLimitPath!)
    ) as number;

    const bundleIncrement = planBundleIncrementPath ? (get(currentPlan, planBundleIncrementPath) as number) : undefined;

    let isOverLimit;
    let percentage;
    let extra;

    if (bundleIncrement !== undefined) {
      const limitIncrease = bundlesQuantity * bundleIncrement;
      const totalLimit = baseLimit + limitIncrease;

      isOverLimit = amount > totalLimit;
      percentage = amount / totalLimit;

      extra = {
        limitIncrease: humanize(limitIncrease),
        totalLimit: humanize(totalLimit)
      };
    } else {
      isOverLimit = amount > baseLimit;
      percentage = amount / baseLimit;
    }

    // A resource analysis is only useful if there's a limit.
    if (baseLimit) {
      acc[resourceType] = {
        bundleIncrement: bundleIncrement !== undefined ? humanize(bundleIncrement) : undefined,
        amount: amount !== undefined ? humanize(amount) : undefined,
        baseLimit: humanize(baseLimit),
        // Guarding against NaN just in case.
        percentage: isNaN(percentage) ? 0 : percentage * 100,
        isOverLimit,
        unit,
        extra
      };
    }

    return acc;
  }, {} as Record<ResourceType, ResourceAnalysis>);
};
