import { Job, JobType } from "../../generated_protos/admin/admin_job_pb";

export type CorpusJobs = Record<JobType, Job.AsObject[]>;

export const buildJobsMap = (jobs: Job.AsObject[]) => {
  return jobs.reduce((acc, job) => {
    job.corpusIdList.forEach((corpusId) => {
      acc[corpusId] = acc[corpusId] || {};
      acc[corpusId][job.type] = acc[corpusId][job.type] || [];
      acc[corpusId][job.type].push(job);
    });
    return acc;
  }, {} as Record<number, CorpusJobs>);
};
