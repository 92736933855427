import {
  ListInvoicesRequest,
  BillingDataRequest,
  UpdateBillingAddressRequest,
  UpdatePaymentDetailsRequest,
  UpdatePaymentDetailsResponse,
  ReadBillingPeriodDetailsRequest,
  HasDefaultPaymentSourceRequest,
  HasDefaultPaymentSourceResponse,
  BillingAddress,
  UpdateBillingAddressResponse,
  ReadBillingPeriodDetailsResponse,
  ListInvoicesResponse,
  BillingDataResponse
} from "../generated_protos/admin/admin_billing_pb";
import {
  ChangeCustomerPlanRequest,
  ReadCustomerPlanDetailsResponse,
  ChangeCustomerPlanResponse,
  ReadCustomerPlanDetailsRequest
} from "../generated_protos/admin/admin_signup_pb";
import { AdminServicePromiseClient } from "../generated_protos/services_grpc_web_pb";
import { getDeadline } from "./getDeadline";

export const ReadBillingPeriodDetails = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string
): Promise<ReadBillingPeriodDetailsResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ReadBillingPeriodDetailsRequest();

    const metadata = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt,
      deadline: getDeadline(10)
    };
    adminService
      .readBillingPeriodDetails(request, metadata)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: readBillingPeriodDetails");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const ListInvoices = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string
): Promise<ListInvoicesResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ListInvoicesRequest();

    const metadata = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt,
      deadline: getDeadline(3)
    };
    adminService
      .listInvoices(request, metadata)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: listInvoices");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const ReadBillingData = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string
): Promise<BillingDataResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new BillingDataRequest();

    const metadata = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt,
      deadline: getDeadline(3)
    };
    adminService
      .readBillingData(request, metadata)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: getBillingData");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const UpdateBillingAddress = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string,
  billingAddress: BillingAddress
): Promise<UpdateBillingAddressResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new UpdateBillingAddressRequest();
    request.setBillingAddress(billingAddress);

    const metadata = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt,
      deadline: getDeadline(3)
    };
    adminService
      .updateBillingAddress(request, metadata)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: updateBillingAddress");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const UpdatePaymentDetails = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string,
  paymentMethod: string
): Promise<UpdatePaymentDetailsResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new UpdatePaymentDetailsRequest();
    request.setStripePaymentSource(paymentMethod);

    const metadata = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt,
      deadline: getDeadline(3)
    };
    adminService
      .updatePaymentDetails(request, metadata)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: updatePaymentDetails");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const ReadCurrentCustomerPlan = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string
): Promise<ReadCustomerPlanDetailsResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ReadCustomerPlanDetailsRequest();

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };
    adminService
      .readCurrentCustomerPlan(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: readCurrentCustomerPlan");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

/**
 * Makes a call to server to check if there is a default payment source attached to this customer
 * account or not.
 */
export const HasDefaultPaymentSource = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string
): Promise<HasDefaultPaymentSourceResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new HasDefaultPaymentSourceRequest();
    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };
    adminService
      .hasDefaultPaymentSource(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: hasPaymentSource");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

/**
 * Calls the server to change customer plan. Passes the requested plan Id.
 */
export const ChangeCustomerPlan = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string,
  newPlanId: string
): Promise<ChangeCustomerPlanResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ChangeCustomerPlanRequest();
    request.setNewPlanId(newPlanId);
    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };
    adminService
      .changeCustomerPlan(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: changeCustomerPlan");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};
