import { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  VuiCallout,
  VuiCard,
  VuiFlexContainer,
  VuiFlexItem,
  VuiLink,
  VuiSpacer,
  VuiTitle,
  VuiButtonPrimary,
  VuiFormGroup,
  VuiTextInput
} from "@vectara/vectara-ui";
import { recoverPassword } from "../../../utils/auth/passwordAuth";
import { GetCustomerLoginAttribs, InitiatePasswordRecoveryFlow } from "../../../admin/PublicEndpoint";
import { useNotificationsContext } from "../../../contexts/NotificationsContext";
import { useApiContext } from "../../../contexts/ApiContext";
import { isNullOrWhitespace } from "../../../utils/isNullOrWhitespace";
import { Field } from "../types";
import { StatusCode } from "../../../generated_protos/status_pb";
import { ORY_POOL_PREFIX } from "../../../backendConfig";
import logo from "../../../images/logo-vectara-dark.png";
import { extractCustomerId, extractUserName } from "../../../utils/queryParams";

type Form = {
  customerId: Field;
  username: Field;
};

type Fields = keyof Form;

export const RequestPasswordResetPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { addNotification } = useNotificationsContext();
  const { PublicAdminService } = useApiContext();
  const [isPasswordResetRequired, setIsPasswordResetRequired] = useState(false);
  const suggestedCustomerId = extractCustomerId(searchParams);
  const suggestedUsername = extractUserName(searchParams);

  const [form, setForm] = useState<Form>({
    customerId: {
      value: "",
      isValid: true
    },
    username: {
      value: "",
      isValid: true
    }
  });

  const [isRequestingPasswordReset, setIsRequestingPasswordReset] = useState(false);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    const { customerId, userName, isPasswordResetRequired } = location?.state || {};
    setForm({
      customerId: {
        value: customerId || suggestedCustomerId || "",
        isValid: true
      },
      username: {
        value: userName || suggestedUsername || "",
        isValid: true
      }
    });

    setIsPasswordResetRequired(isPasswordResetRequired);
  }, [location?.state]);

  const setField = (name: Fields, value: string) => {
    setForm({ ...form, [name]: { value: value, isValid: value.length > 0 } });
  };

  const validateForm = () => {
    const { customerId, username } = form;

    const isCustomerIdValid =
      customerId.value.length > 0 && customerId.value.length <= 11 && !isNaN(Number(customerId.value));
    const isUsernameValid = !isNullOrWhitespace(username.value);

    setForm({
      customerId: { ...form.customerId, isValid: isCustomerIdValid },
      username: { ...form.username, isValid: isUsernameValid }
    });

    return isCustomerIdValid && isUsernameValid;
  };

  const requestPasswordReset = async () => {
    if (!validateForm()) return;

    try {
      setIsRequestingPasswordReset(true);
      const { customerId, username } = form;

      const { region, userPoolId, userPoolWebClientId } = await GetCustomerLoginAttribs(
        PublicAdminService,
        customerId.value
      );

      const isOryAuth = userPoolId.startsWith(ORY_POOL_PREFIX);

      if (isOryAuth) {
        try {
          const response = await InitiatePasswordRecoveryFlow(
            PublicAdminService,
            Number(customerId.value),
            username.value
          );
          if (response.status?.code === StatusCode.OK) {
            setIsRequestingPasswordReset(false);
            setError("");
            // TODO: for better UX, we should redirect to a page that shows a
            // message that the recovery code has been sent to the user's email
            addNotification(`Recovery code sent to your email. Please check your inbox for recovery code.`, "success");
          } else {
            setIsRequestingPasswordReset(false);
            setError(response.status?.statusDetail);
          }
        } catch (e: any) {
          console.log(`ory flow failure: ${e}`);
          setIsRequestingPasswordReset(false);
          setError(`Unknown failure: ${e.message}`);
        }
      } else {
        try {
          const result = await recoverPassword(username.value, {
            awsRegion: region,
            userPoolId,
            userPoolWebClientId
          });

          setIsRequestingPasswordReset(false);
          navigate("/setNewPassword", {
            replace: true,
            state: {
              customerId: customerId.value,
              userName: username.value
            }
          });
          setError("");
          addNotification(
            `Recovery code sent via ${result.CodeDeliveryDetails.DeliveryMedium} to ${result.CodeDeliveryDetails.Destination}`,
            "success"
          );
        } catch (e: any) {
          setIsRequestingPasswordReset(false);
          setError(`Unknown failure: ${e.message}`);
          console.log(e);
        }
      }
    } catch (e: any) {
      setIsRequestingPasswordReset(false);

      let error = `Unknown failure: ${e.message}`;
      if (e.message === "ADM__INVALID_CUSTOMER_ID") {
        error = "Invalid customer number. Please check your entry.";
        setForm({
          ...form,
          customerId: {
            ...form.customerId,
            isValid: false
          }
        });
      }

      console.log(e);
      addNotification(error, "danger");
      setError(error);
    }
  };

  return (
    <div>
      <VuiCard
        padding="m"
        className="loggedOutPageForm"
        header={
          <VuiFlexContainer
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing="s"
            className="loggedOutFormHeader"
          >
            <VuiFlexItem grow={false} shrink={false}>
              <VuiLink href="/">
                <img src={logo} alt="Vectara logo" className="loggedOutFormLogo" />
              </VuiLink>
            </VuiFlexItem>

            <VuiFlexItem grow={1} shrink={1}>
              <VuiTitle size="s" align="center" data-testid="requestPasswordResetTitle">
                <h1>Reset your password</h1>
              </VuiTitle>
            </VuiFlexItem>
          </VuiFlexContainer>
        }
        body={
          <>
            <VuiCallout
              title={`${
                isPasswordResetRequired ? "You need to reset your password before logging in. " : ""
              }Enter your customer number and email or username.`}
              color={isPasswordResetRequired ? "warning" : "primary"}
              headingElement="h2"
              size="s"
              data-testid="requestPasswordResetCallout"
            />

            <VuiSpacer size="l" />

            {error && (
              <>
                <VuiCallout color="danger" headingElement="h2" title="Couldn't reset password">
                  {error}
                </VuiCallout>
                <VuiSpacer size="l" />
              </>
            )}

            {/* Form and submit button might be required by user-agent for autocompletion of fields.
            https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete */}
            <form onSubmit={(e) => e.preventDefault()}>
              <button type="submit" style={{ display: "none" }} />

              <VuiFormGroup
                label="Customer ID"
                labelFor="customerId"
                helpText="Your customer number is a numeric code of up to ten digits. You can find it in your welcome email."
                errors={!form.customerId.isValid ? ["Enter valid customer ID."] : undefined}
              >
                <VuiTextInput
                  fullWidth
                  id="customerId"
                  name="customerId"
                  autoComplete="customerId"
                  value={form.customerId.value}
                  onChange={(e) => setField("customerId", e.target.value.trim())}
                  onSubmit={requestPasswordReset}
                  data-testid="customerIdInput"
                />
              </VuiFormGroup>

              <VuiSpacer size="l" />

              <VuiFormGroup
                label="Email or username"
                labelFor="username"
                helpText="If you're an account owner, enter your email. If you're a team member, enter the username assigned to you in your welcome email."
                errors={!form.username.isValid ? ["Enter valid email or username."] : undefined}
              >
                <VuiTextInput
                  fullWidth
                  id="username"
                  name="username"
                  autoComplete="username"
                  value={form.username.value}
                  onChange={(e) => setField("username", e.target.value.trim())}
                  onSubmit={requestPasswordReset}
                  data-testid="usernameInput"
                />
              </VuiFormGroup>

              <VuiSpacer size="l" />

              <VuiButtonPrimary
                color="primary"
                isDisabled={isRequestingPasswordReset}
                fullWidth
                onClick={requestPasswordReset}
                isLoading={isRequestingPasswordReset}
              >
                {isRequestingPasswordReset ? "Resetting password…" : "Reset password"}
              </VuiButtonPrimary>
            </form>
          </>
        }
      />
    </div>
  );
};
