// source: services.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var admin_admin_account_pb = require('./admin/admin_account_pb.js');
goog.object.extend(proto, admin_admin_account_pb);
var admin_admin_apikey_pb = require('./admin/admin_apikey_pb.js');
goog.object.extend(proto, admin_admin_apikey_pb);
var admin_admin_corpus_pb = require('./admin/admin_corpus_pb.js');
goog.object.extend(proto, admin_admin_corpus_pb);
var admin_admin_job_pb = require('./admin/admin_job_pb.js');
goog.object.extend(proto, admin_admin_job_pb);
var admin_admin_metric_pb = require('./admin/admin_metric_pb.js');
goog.object.extend(proto, admin_admin_metric_pb);
var admin_admin_security_pb = require('./admin/admin_security_pb.js');
goog.object.extend(proto, admin_admin_security_pb);
var admin_admin_user_pb = require('./admin/admin_user_pb.js');
goog.object.extend(proto, admin_admin_user_pb);
var admin_admin_appclient_pb = require('./admin/admin_appclient_pb.js');
goog.object.extend(proto, admin_admin_appclient_pb);
var admin_admin_billing_pb = require('./admin/admin_billing_pb.js');
goog.object.extend(proto, admin_admin_billing_pb);
var admin_admin_corpusauth_pb = require('./admin/admin_corpusauth_pb.js');
goog.object.extend(proto, admin_admin_corpusauth_pb);
var admin_admin_encoder_pb = require('./admin/admin_encoder_pb.js');
goog.object.extend(proto, admin_admin_encoder_pb);
var admin_admin_feature_pb = require('./admin/admin_feature_pb.js');
goog.object.extend(proto, admin_admin_feature_pb);
var admin_admin_permission_pb = require('./admin/admin_permission_pb.js');
goog.object.extend(proto, admin_admin_permission_pb);
var admin_admin_reranker_pb = require('./admin/admin_reranker_pb.js');
goog.object.extend(proto, admin_admin_reranker_pb);
var admin_admin_signup_pb = require('./admin/admin_signup_pb.js');
goog.object.extend(proto, admin_admin_signup_pb);
var admin_admin_stats_pb = require('./admin/admin_stats_pb.js');
goog.object.extend(proto, admin_admin_stats_pb);
var admin_admin_summarizer_pb = require('./admin/admin_summarizer_pb.js');
goog.object.extend(proto, admin_admin_summarizer_pb);
var chat_pb = require('./chat_pb.js');
goog.object.extend(proto, chat_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var indexing_pb = require('./indexing_pb.js');
goog.object.extend(proto, indexing_pb);
var serving_pb = require('./serving_pb.js');
goog.object.extend(proto, serving_pb);
var status_pb = require('./status_pb.js');
goog.object.extend(proto, status_pb);
var list_documents_pb = require('./list_documents_pb.js');
goog.object.extend(proto, list_documents_pb);
var google_api_annotations_pb = require('./google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
goog.exportSymbol('proto.com.vectara.IndexDocumentRequest', null, global);
goog.exportSymbol('proto.com.vectara.IndexDocumentResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.IndexDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.IndexDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.IndexDocumentRequest.displayName = 'proto.com.vectara.IndexDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.IndexDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.IndexDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.IndexDocumentResponse.displayName = 'proto.com.vectara.IndexDocumentResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.IndexDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.IndexDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.IndexDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.IndexDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    corpusId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    document: (f = msg.getDocument()) && indexing_pb.Document.toObject(includeInstance, f),
    config: (f = msg.getConfig()) && common_pb.IndexConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.IndexDocumentRequest}
 */
proto.com.vectara.IndexDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.IndexDocumentRequest;
  return proto.com.vectara.IndexDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.IndexDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.IndexDocumentRequest}
 */
proto.com.vectara.IndexDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCorpusId(value);
      break;
    case 3:
      var value = new indexing_pb.Document;
      reader.readMessage(value,indexing_pb.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    case 5:
      var value = new common_pb.IndexConfig;
      reader.readMessage(value,common_pb.IndexConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.IndexDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.IndexDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.IndexDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.IndexDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      indexing_pb.Document.serializeBinaryToWriter
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.IndexConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 customer_id = 1;
 * @return {number}
 */
proto.com.vectara.IndexDocumentRequest.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.IndexDocumentRequest} returns this
 */
proto.com.vectara.IndexDocumentRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 corpus_id = 2;
 * @return {number}
 */
proto.com.vectara.IndexDocumentRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.IndexDocumentRequest} returns this
 */
proto.com.vectara.IndexDocumentRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional indexing.Document document = 3;
 * @return {?proto.com.vectara.indexing.Document}
 */
proto.com.vectara.IndexDocumentRequest.prototype.getDocument = function() {
  return /** @type{?proto.com.vectara.indexing.Document} */ (
    jspb.Message.getWrapperField(this, indexing_pb.Document, 3));
};


/**
 * @param {?proto.com.vectara.indexing.Document|undefined} value
 * @return {!proto.com.vectara.IndexDocumentRequest} returns this
*/
proto.com.vectara.IndexDocumentRequest.prototype.setDocument = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.IndexDocumentRequest} returns this
 */
proto.com.vectara.IndexDocumentRequest.prototype.clearDocument = function() {
  return this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.IndexDocumentRequest.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional IndexConfig config = 5;
 * @return {?proto.com.vectara.IndexConfig}
 */
proto.com.vectara.IndexDocumentRequest.prototype.getConfig = function() {
  return /** @type{?proto.com.vectara.IndexConfig} */ (
    jspb.Message.getWrapperField(this, common_pb.IndexConfig, 5));
};


/**
 * @param {?proto.com.vectara.IndexConfig|undefined} value
 * @return {!proto.com.vectara.IndexDocumentRequest} returns this
*/
proto.com.vectara.IndexDocumentRequest.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.IndexDocumentRequest} returns this
 */
proto.com.vectara.IndexDocumentRequest.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.IndexDocumentRequest.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.IndexDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.IndexDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.IndexDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.IndexDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f),
    quotaConsumed: (f = msg.getQuotaConsumed()) && common_pb.StorageQuota.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.IndexDocumentResponse}
 */
proto.com.vectara.IndexDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.IndexDocumentResponse;
  return proto.com.vectara.IndexDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.IndexDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.IndexDocumentResponse}
 */
proto.com.vectara.IndexDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 2:
      var value = new common_pb.StorageQuota;
      reader.readMessage(value,common_pb.StorageQuota.deserializeBinaryFromReader);
      msg.setQuotaConsumed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.IndexDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.IndexDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.IndexDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.IndexDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getQuotaConsumed();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.StorageQuota.serializeBinaryToWriter
    );
  }
};


/**
 * optional Status status = 1;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.IndexDocumentResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.IndexDocumentResponse} returns this
*/
proto.com.vectara.IndexDocumentResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.IndexDocumentResponse} returns this
 */
proto.com.vectara.IndexDocumentResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.IndexDocumentResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StorageQuota quota_consumed = 2;
 * @return {?proto.com.vectara.StorageQuota}
 */
proto.com.vectara.IndexDocumentResponse.prototype.getQuotaConsumed = function() {
  return /** @type{?proto.com.vectara.StorageQuota} */ (
    jspb.Message.getWrapperField(this, common_pb.StorageQuota, 2));
};


/**
 * @param {?proto.com.vectara.StorageQuota|undefined} value
 * @return {!proto.com.vectara.IndexDocumentResponse} returns this
*/
proto.com.vectara.IndexDocumentResponse.prototype.setQuotaConsumed = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.IndexDocumentResponse} returns this
 */
proto.com.vectara.IndexDocumentResponse.prototype.clearQuotaConsumed = function() {
  return this.setQuotaConsumed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.IndexDocumentResponse.prototype.hasQuotaConsumed = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.com.vectara);
