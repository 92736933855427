import { useLocation } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import { VuiAppHeader, VuiFlexContainer, VuiFlexItem, VuiIcon, VuiIconButton } from "@vectara/vectara-ui";
import { AccountMenu } from "./accountMenu/AccountMenu";
import { useUserContext } from "../../../contexts/UserContext";
import { UsageMeter } from "./UsageMeter";
import { useLayoutContext } from "../../../contexts/LayoutContext";
import { useUpgradeContext } from "../../../contexts/UpgradeContext";
import { sectionToName } from "../appNav/AppNav";
import { HeaderBreadcrumbLabel } from "./HeaderBreadcrumbLabel";
import { ApiMenu } from "./apiMenu/ApiMenu";
import "./AppHeader.scss";
import { useSurveyContext } from "../../../contexts/SurveyContext";

const humanizePathPart = (part: string) => part.charAt(0).toUpperCase() + part.slice(1);

function AppHeader() {
  const { isSurveyActive } = useSurveyContext();
  const { customer, isAwsMpCustomer } = useUserContext();
  const { setIsNavOpen, isNavPinnedOpen, customHeaderContent } = useLayoutContext();
  const { upgradeDisabled } = useUpgradeContext();
  const { pathname } = useLocation();

  // TODO: Ensure this branch is never hit.
  if (!customer) {
    return <div>…</div>;
  }

  const navPath = pathname.split("/");
  const baseNavPath = navPath[2];
  const pageName = sectionToName[baseNavPath as keyof typeof sectionToName] ?? "";
  const headerContent = customHeaderContent ?? (
    <HeaderBreadcrumbLabel>{humanizePathPart(pageName)}</HeaderBreadcrumbLabel>
  );

  if (isSurveyActive) return null;

  return (
    <VuiAppHeader
      data-testid="loggedInHeader"
      growRight
      left={
        <VuiFlexContainer alignItems="center" spacing="xxs">
          {!isNavPinnedOpen && (
            <VuiFlexItem>
              <VuiIconButton
                aria-label="Show navigation"
                data-testid="openNavButton"
                icon={
                  <VuiIcon>
                    <BiMenu />
                  </VuiIcon>
                }
                onMouseMove={(e) => {
                  // Prevent movement on the button itself from closing the nav
                  // immediately after the user clicks on it.
                  e.stopPropagation();
                }}
                onClick={() => {
                  setIsNavOpen(true);
                }}
              />
            </VuiFlexItem>
          )}

          {headerContent}
        </VuiFlexContainer>
      }
      right={
        <VuiFlexContainer justifyContent="end" alignItems="center" spacing="s" className="appHeaderSearchContainer">
          <VuiFlexItem grow={false}>
            <ApiMenu />
          </VuiFlexItem>

          {!upgradeDisabled && !isAwsMpCustomer && (
            <VuiFlexItem grow={false}>
              <UsageMeter />
            </VuiFlexItem>
          )}

          <VuiFlexItem grow={false}>
            <AccountMenu />
          </VuiFlexItem>
        </VuiFlexContainer>
      }
    />
  );
}

export default AppHeader;
