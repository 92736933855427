// source: admin/admin_apikey.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var admin_admin_metric_pb = require('../admin/admin_metric_pb.js');
goog.object.extend(proto, admin_admin_metric_pb);
var status_pb = require('../status_pb.js');
goog.object.extend(proto, status_pb);
goog.exportSymbol('proto.com.vectara.admin.ApiKey', null, global);
goog.exportSymbol('proto.com.vectara.admin.ApiKeyStatus', null, global);
goog.exportSymbol('proto.com.vectara.admin.ApiKeyType', null, global);
goog.exportSymbol('proto.com.vectara.admin.CreateApiKeyRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus', null, global);
goog.exportSymbol('proto.com.vectara.admin.CreateApiKeyResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus', null, global);
goog.exportSymbol('proto.com.vectara.admin.DeleteApiKeyRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.DeleteApiKeyResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.EnableApiKeyRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement', null, global);
goog.exportSymbol('proto.com.vectara.admin.EnableApiKeyResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListApiKeysRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListApiKeysResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadApiKeyRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadApiKeyResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ApiKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ApiKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ApiKey.displayName = 'proto.com.vectara.admin.ApiKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.CreateApiKeyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.CreateApiKeyRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.CreateApiKeyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.CreateApiKeyRequest.displayName = 'proto.com.vectara.admin.CreateApiKeyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.displayName = 'proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.CreateApiKeyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.CreateApiKeyResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.CreateApiKeyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.CreateApiKeyResponse.displayName = 'proto.com.vectara.admin.CreateApiKeyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.displayName = 'proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListApiKeysRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.ListApiKeysRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ListApiKeysRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListApiKeysRequest.displayName = 'proto.com.vectara.admin.ListApiKeysRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListApiKeysResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.ListApiKeysResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ListApiKeysResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListApiKeysResponse.displayName = 'proto.com.vectara.admin.ListApiKeysResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.displayName = 'proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.displayName = 'proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.DeleteApiKeyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.DeleteApiKeyRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.DeleteApiKeyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.DeleteApiKeyRequest.displayName = 'proto.com.vectara.admin.DeleteApiKeyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.DeleteApiKeyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.DeleteApiKeyResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.DeleteApiKeyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.DeleteApiKeyResponse.displayName = 'proto.com.vectara.admin.DeleteApiKeyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.EnableApiKeyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.EnableApiKeyRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.EnableApiKeyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.EnableApiKeyRequest.displayName = 'proto.com.vectara.admin.EnableApiKeyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.displayName = 'proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.EnableApiKeyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.EnableApiKeyResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.EnableApiKeyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.EnableApiKeyResponse.displayName = 'proto.com.vectara.admin.EnableApiKeyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadApiKeyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ReadApiKeyRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ReadApiKeyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadApiKeyRequest.displayName = 'proto.com.vectara.admin.ReadApiKeyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.displayName = 'proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadApiKeyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ReadApiKeyResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ReadApiKeyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadApiKeyResponse.displayName = 'proto.com.vectara.admin.ReadApiKeyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.displayName = 'proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.displayName = 'proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ApiKey.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ApiKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ApiKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ApiKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    keyType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    tsStart: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tsEnd: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    publicId: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ApiKey}
 */
proto.com.vectara.admin.ApiKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ApiKey;
  return proto.com.vectara.admin.ApiKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ApiKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ApiKey}
 */
proto.com.vectara.admin.ApiKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {!proto.com.vectara.admin.ApiKeyType} */ (reader.readEnum());
      msg.setKeyType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTsStart(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTsEnd(value);
      break;
    case 7:
      var value = /** @type {!proto.com.vectara.admin.ApiKeyStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPublicId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ApiKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ApiKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ApiKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ApiKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKeyType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getPublicId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.vectara.admin.ApiKey.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ApiKey} returns this
 */
proto.com.vectara.admin.ApiKey.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.com.vectara.admin.ApiKey.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ApiKey} returns this
 */
proto.com.vectara.admin.ApiKey.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ApiKeyType key_type = 3;
 * @return {!proto.com.vectara.admin.ApiKeyType}
 */
proto.com.vectara.admin.ApiKey.prototype.getKeyType = function() {
  return /** @type {!proto.com.vectara.admin.ApiKeyType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.vectara.admin.ApiKeyType} value
 * @return {!proto.com.vectara.admin.ApiKey} returns this
 */
proto.com.vectara.admin.ApiKey.prototype.setKeyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool enabled = 4;
 * @return {boolean}
 */
proto.com.vectara.admin.ApiKey.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.ApiKey} returns this
 */
proto.com.vectara.admin.ApiKey.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 ts_start = 5;
 * @return {number}
 */
proto.com.vectara.admin.ApiKey.prototype.getTsStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ApiKey} returns this
 */
proto.com.vectara.admin.ApiKey.prototype.setTsStart = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.admin.ApiKey} returns this
 */
proto.com.vectara.admin.ApiKey.prototype.clearTsStart = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ApiKey.prototype.hasTsStart = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 ts_end = 6;
 * @return {number}
 */
proto.com.vectara.admin.ApiKey.prototype.getTsEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ApiKey} returns this
 */
proto.com.vectara.admin.ApiKey.prototype.setTsEnd = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.admin.ApiKey} returns this
 */
proto.com.vectara.admin.ApiKey.prototype.clearTsEnd = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ApiKey.prototype.hasTsEnd = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ApiKeyStatus status = 7;
 * @return {!proto.com.vectara.admin.ApiKeyStatus}
 */
proto.com.vectara.admin.ApiKey.prototype.getStatus = function() {
  return /** @type {!proto.com.vectara.admin.ApiKeyStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.com.vectara.admin.ApiKeyStatus} value
 * @return {!proto.com.vectara.admin.ApiKey} returns this
 */
proto.com.vectara.admin.ApiKey.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional uint32 public_id = 8;
 * @return {number}
 */
proto.com.vectara.admin.ApiKey.prototype.getPublicId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ApiKey} returns this
 */
proto.com.vectara.admin.ApiKey.prototype.setPublicId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.CreateApiKeyRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.CreateApiKeyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.CreateApiKeyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.CreateApiKeyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CreateApiKeyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiKeyDataList: jspb.Message.toObjectList(msg.getApiKeyDataList(),
    proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.CreateApiKeyRequest}
 */
proto.com.vectara.admin.CreateApiKeyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.CreateApiKeyRequest;
  return proto.com.vectara.admin.CreateApiKeyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.CreateApiKeyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.CreateApiKeyRequest}
 */
proto.com.vectara.admin.CreateApiKeyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus;
      reader.readMessage(value,proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.deserializeBinaryFromReader);
      msg.addApiKeyData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.CreateApiKeyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.CreateApiKeyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.CreateApiKeyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CreateApiKeyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiKeyDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    apiKeyType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    corpusIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus}
 */
proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus;
  return proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus}
 */
proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {!proto.com.vectara.admin.ApiKeyType} */ (reader.readEnum());
      msg.setApiKeyType(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCorpusId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApiKeyType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCorpusIdList();
  if (f.length > 0) {
    writer.writePackedUint32(
      2,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus} returns this
 */
proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ApiKeyType api_key_type = 3;
 * @return {!proto.com.vectara.admin.ApiKeyType}
 */
proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.prototype.getApiKeyType = function() {
  return /** @type {!proto.com.vectara.admin.ApiKeyType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.vectara.admin.ApiKeyType} value
 * @return {!proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus} returns this
 */
proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.prototype.setApiKeyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated uint32 corpus_id = 2;
 * @return {!Array<number>}
 */
proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.prototype.getCorpusIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus} returns this
 */
proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.prototype.setCorpusIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus} returns this
 */
proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.prototype.addCorpusId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus} returns this
 */
proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus.prototype.clearCorpusIdList = function() {
  return this.setCorpusIdList([]);
};


/**
 * repeated ApiKeyCorpus api_key_data = 1;
 * @return {!Array<!proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus>}
 */
proto.com.vectara.admin.CreateApiKeyRequest.prototype.getApiKeyDataList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus>} value
 * @return {!proto.com.vectara.admin.CreateApiKeyRequest} returns this
*/
proto.com.vectara.admin.CreateApiKeyRequest.prototype.setApiKeyDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus}
 */
proto.com.vectara.admin.CreateApiKeyRequest.prototype.addApiKeyData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.CreateApiKeyRequest.ApiKeyCorpus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.CreateApiKeyRequest} returns this
 */
proto.com.vectara.admin.CreateApiKeyRequest.prototype.clearApiKeyDataList = function() {
  return this.setApiKeyDataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.CreateApiKeyResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.CreateApiKeyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.CreateApiKeyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.CreateApiKeyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CreateApiKeyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseList: jspb.Message.toObjectList(msg.getResponseList(),
    proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.CreateApiKeyResponse}
 */
proto.com.vectara.admin.CreateApiKeyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.CreateApiKeyResponse;
  return proto.com.vectara.admin.CreateApiKeyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.CreateApiKeyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.CreateApiKeyResponse}
 */
proto.com.vectara.admin.CreateApiKeyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus;
      reader.readMessage(value,proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.deserializeBinaryFromReader);
      msg.addResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.CreateApiKeyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.CreateApiKeyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.CreateApiKeyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CreateApiKeyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus}
 */
proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus;
  return proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus}
 */
proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyId(value);
      break;
    case 2:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional string key_id = 1;
 * @return {string}
 */
proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.prototype.getKeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus} returns this
 */
proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.prototype.setKeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional com.vectara.Status status = 2;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 2));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus} returns this
*/
proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus} returns this
 */
proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ApiKeyOrStatus response = 1;
 * @return {!Array<!proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus>}
 */
proto.com.vectara.admin.CreateApiKeyResponse.prototype.getResponseList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus>} value
 * @return {!proto.com.vectara.admin.CreateApiKeyResponse} returns this
*/
proto.com.vectara.admin.CreateApiKeyResponse.prototype.setResponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus}
 */
proto.com.vectara.admin.CreateApiKeyResponse.prototype.addResponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.CreateApiKeyResponse.ApiKeyOrStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.CreateApiKeyResponse} returns this
 */
proto.com.vectara.admin.CreateApiKeyResponse.prototype.clearResponseList = function() {
  return this.setResponseList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ListApiKeysRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListApiKeysRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListApiKeysRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListApiKeysRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListApiKeysRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    numResults: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageKey: msg.getPageKey_asB64(),
    apiKeyTypeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    readCorporaInfo: jspb.Message.getBooleanFieldWithDefault(msg, 1000, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListApiKeysRequest}
 */
proto.com.vectara.admin.ListApiKeysRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListApiKeysRequest;
  return proto.com.vectara.admin.ListApiKeysRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListApiKeysRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListApiKeysRequest}
 */
proto.com.vectara.admin.ListApiKeysRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumResults(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.com.vectara.admin.ApiKeyType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addApiKeyType(values[i]);
      }
      break;
    case 1000:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadCorporaInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListApiKeysRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListApiKeysRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListApiKeysRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListApiKeysRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumResults();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getApiKeyTypeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getReadCorporaInfo();
  if (f) {
    writer.writeBool(
      1000,
      f
    );
  }
};


/**
 * optional uint32 num_results = 1;
 * @return {number}
 */
proto.com.vectara.admin.ListApiKeysRequest.prototype.getNumResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ListApiKeysRequest} returns this
 */
proto.com.vectara.admin.ListApiKeysRequest.prototype.setNumResults = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bytes page_key = 2;
 * @return {string}
 */
proto.com.vectara.admin.ListApiKeysRequest.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes page_key = 2;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.admin.ListApiKeysRequest.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListApiKeysRequest.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.admin.ListApiKeysRequest} returns this
 */
proto.com.vectara.admin.ListApiKeysRequest.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * repeated ApiKeyType api_key_type = 3;
 * @return {!Array<!proto.com.vectara.admin.ApiKeyType>}
 */
proto.com.vectara.admin.ListApiKeysRequest.prototype.getApiKeyTypeList = function() {
  return /** @type {!Array<!proto.com.vectara.admin.ApiKeyType>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.com.vectara.admin.ApiKeyType>} value
 * @return {!proto.com.vectara.admin.ListApiKeysRequest} returns this
 */
proto.com.vectara.admin.ListApiKeysRequest.prototype.setApiKeyTypeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.com.vectara.admin.ApiKeyType} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ListApiKeysRequest} returns this
 */
proto.com.vectara.admin.ListApiKeysRequest.prototype.addApiKeyType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ListApiKeysRequest} returns this
 */
proto.com.vectara.admin.ListApiKeysRequest.prototype.clearApiKeyTypeList = function() {
  return this.setApiKeyTypeList([]);
};


/**
 * optional bool read_corpora_info = 1000;
 * @return {boolean}
 */
proto.com.vectara.admin.ListApiKeysRequest.prototype.getReadCorporaInfo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1000, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.ListApiKeysRequest} returns this
 */
proto.com.vectara.admin.ListApiKeysRequest.prototype.setReadCorporaInfo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1000, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ListApiKeysResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListApiKeysResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListApiKeysResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListApiKeysResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListApiKeysResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyDataList: jspb.Message.toObjectList(msg.getKeyDataList(),
    proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.toObject, includeInstance),
    pageKey: msg.getPageKey_asB64(),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListApiKeysResponse}
 */
proto.com.vectara.admin.ListApiKeysResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListApiKeysResponse;
  return proto.com.vectara.admin.ListApiKeysResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListApiKeysResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListApiKeysResponse}
 */
proto.com.vectara.admin.ListApiKeysResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora;
      reader.readMessage(value,proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.deserializeBinaryFromReader);
      msg.addKeyData(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListApiKeysResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListApiKeysResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListApiKeysResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListApiKeysResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.serializeBinaryToWriter
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiKey: (f = msg.getApiKey()) && proto.com.vectara.admin.ApiKey.toObject(includeInstance, f),
    corpusList: jspb.Message.toObjectList(msg.getCorpusList(),
    proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora}
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora;
  return proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora}
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.ApiKey;
      reader.readMessage(value,proto.com.vectara.admin.ApiKey.deserializeBinaryFromReader);
      msg.setApiKey(value);
      break;
    case 2:
      var value = new proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo;
      reader.readMessage(value,proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.deserializeBinaryFromReader);
      msg.addCorpus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiKey();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.vectara.admin.ApiKey.serializeBinaryToWriter
    );
  }
  f = message.getCorpusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo}
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo;
  return proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo}
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo} returns this
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo} returns this
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ApiKey api_key = 1;
 * @return {?proto.com.vectara.admin.ApiKey}
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.prototype.getApiKey = function() {
  return /** @type{?proto.com.vectara.admin.ApiKey} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.ApiKey, 1));
};


/**
 * @param {?proto.com.vectara.admin.ApiKey|undefined} value
 * @return {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora} returns this
*/
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.prototype.setApiKey = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora} returns this
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.prototype.clearApiKey = function() {
  return this.setApiKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.prototype.hasApiKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated CorpusInfo corpus = 2;
 * @return {!Array<!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo>}
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.prototype.getCorpusList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo, 2));
};


/**
 * @param {!Array<!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo>} value
 * @return {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora} returns this
*/
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.prototype.setCorpusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo}
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.prototype.addCorpus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.CorpusInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora} returns this
 */
proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora.prototype.clearCorpusList = function() {
  return this.setCorpusList([]);
};


/**
 * repeated KeyAndCorpora key_data = 1;
 * @return {!Array<!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora>}
 */
proto.com.vectara.admin.ListApiKeysResponse.prototype.getKeyDataList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora>} value
 * @return {!proto.com.vectara.admin.ListApiKeysResponse} returns this
*/
proto.com.vectara.admin.ListApiKeysResponse.prototype.setKeyDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora}
 */
proto.com.vectara.admin.ListApiKeysResponse.prototype.addKeyData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.ListApiKeysResponse.KeyAndCorpora, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ListApiKeysResponse} returns this
 */
proto.com.vectara.admin.ListApiKeysResponse.prototype.clearKeyDataList = function() {
  return this.setKeyDataList([]);
};


/**
 * optional bytes page_key = 2;
 * @return {string}
 */
proto.com.vectara.admin.ListApiKeysResponse.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes page_key = 2;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.admin.ListApiKeysResponse.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListApiKeysResponse.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.admin.ListApiKeysResponse} returns this
 */
proto.com.vectara.admin.ListApiKeysResponse.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ListApiKeysResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ListApiKeysResponse} returns this
*/
proto.com.vectara.admin.ListApiKeysResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ListApiKeysResponse} returns this
 */
proto.com.vectara.admin.ListApiKeysResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ListApiKeysResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.DeleteApiKeyRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.DeleteApiKeyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.DeleteApiKeyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.DeleteApiKeyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.DeleteApiKeyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.DeleteApiKeyRequest}
 */
proto.com.vectara.admin.DeleteApiKeyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.DeleteApiKeyRequest;
  return proto.com.vectara.admin.DeleteApiKeyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.DeleteApiKeyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.DeleteApiKeyRequest}
 */
proto.com.vectara.admin.DeleteApiKeyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addKeyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.DeleteApiKeyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.DeleteApiKeyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.DeleteApiKeyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.DeleteApiKeyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string key_id = 1;
 * @return {!Array<string>}
 */
proto.com.vectara.admin.DeleteApiKeyRequest.prototype.getKeyIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.vectara.admin.DeleteApiKeyRequest} returns this
 */
proto.com.vectara.admin.DeleteApiKeyRequest.prototype.setKeyIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.DeleteApiKeyRequest} returns this
 */
proto.com.vectara.admin.DeleteApiKeyRequest.prototype.addKeyId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.DeleteApiKeyRequest} returns this
 */
proto.com.vectara.admin.DeleteApiKeyRequest.prototype.clearKeyIdList = function() {
  return this.setKeyIdList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.DeleteApiKeyResponse.repeatedFields_ = [1000];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.DeleteApiKeyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.DeleteApiKeyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.DeleteApiKeyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.DeleteApiKeyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    status_pb.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.DeleteApiKeyResponse}
 */
proto.com.vectara.admin.DeleteApiKeyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.DeleteApiKeyResponse;
  return proto.com.vectara.admin.DeleteApiKeyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.DeleteApiKeyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.DeleteApiKeyResponse}
 */
proto.com.vectara.admin.DeleteApiKeyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.DeleteApiKeyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.DeleteApiKeyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.DeleteApiKeyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.DeleteApiKeyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * repeated com.vectara.Status status = 1000;
 * @return {!Array<!proto.com.vectara.Status>}
 */
proto.com.vectara.admin.DeleteApiKeyResponse.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.com.vectara.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {!Array<!proto.com.vectara.Status>} value
 * @return {!proto.com.vectara.admin.DeleteApiKeyResponse} returns this
*/
proto.com.vectara.admin.DeleteApiKeyResponse.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1000, value);
};


/**
 * @param {!proto.com.vectara.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.Status}
 */
proto.com.vectara.admin.DeleteApiKeyResponse.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1000, opt_value, proto.com.vectara.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.DeleteApiKeyResponse} returns this
 */
proto.com.vectara.admin.DeleteApiKeyResponse.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.EnableApiKeyRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.EnableApiKeyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.EnableApiKeyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.EnableApiKeyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.EnableApiKeyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyEnablementList: jspb.Message.toObjectList(msg.getKeyEnablementList(),
    proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.EnableApiKeyRequest}
 */
proto.com.vectara.admin.EnableApiKeyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.EnableApiKeyRequest;
  return proto.com.vectara.admin.EnableApiKeyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.EnableApiKeyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.EnableApiKeyRequest}
 */
proto.com.vectara.admin.EnableApiKeyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement;
      reader.readMessage(value,proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.deserializeBinaryFromReader);
      msg.addKeyEnablement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.EnableApiKeyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.EnableApiKeyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.EnableApiKeyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.EnableApiKeyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyEnablementList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement}
 */
proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement;
  return proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement}
 */
proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string key_id = 1;
 * @return {string}
 */
proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.prototype.getKeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement} returns this
 */
proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.prototype.setKeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool enable = 2;
 * @return {boolean}
 */
proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement} returns this
 */
proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated ApiKeyEnablement key_enablement = 1;
 * @return {!Array<!proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement>}
 */
proto.com.vectara.admin.EnableApiKeyRequest.prototype.getKeyEnablementList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement>} value
 * @return {!proto.com.vectara.admin.EnableApiKeyRequest} returns this
*/
proto.com.vectara.admin.EnableApiKeyRequest.prototype.setKeyEnablementList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement}
 */
proto.com.vectara.admin.EnableApiKeyRequest.prototype.addKeyEnablement = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.EnableApiKeyRequest.ApiKeyEnablement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.EnableApiKeyRequest} returns this
 */
proto.com.vectara.admin.EnableApiKeyRequest.prototype.clearKeyEnablementList = function() {
  return this.setKeyEnablementList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.EnableApiKeyResponse.repeatedFields_ = [1000];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.EnableApiKeyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.EnableApiKeyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.EnableApiKeyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.EnableApiKeyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    status_pb.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.EnableApiKeyResponse}
 */
proto.com.vectara.admin.EnableApiKeyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.EnableApiKeyResponse;
  return proto.com.vectara.admin.EnableApiKeyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.EnableApiKeyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.EnableApiKeyResponse}
 */
proto.com.vectara.admin.EnableApiKeyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.EnableApiKeyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.EnableApiKeyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.EnableApiKeyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.EnableApiKeyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * repeated com.vectara.Status status = 1000;
 * @return {!Array<!proto.com.vectara.Status>}
 */
proto.com.vectara.admin.EnableApiKeyResponse.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.com.vectara.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {!Array<!proto.com.vectara.Status>} value
 * @return {!proto.com.vectara.admin.EnableApiKeyResponse} returns this
*/
proto.com.vectara.admin.EnableApiKeyResponse.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1000, value);
};


/**
 * @param {!proto.com.vectara.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.Status}
 */
proto.com.vectara.admin.EnableApiKeyResponse.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1000, opt_value, proto.com.vectara.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.EnableApiKeyResponse} returns this
 */
proto.com.vectara.admin.EnableApiKeyResponse.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ReadApiKeyRequest.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadApiKeyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadApiKeyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadApiKeyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadApiKeyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    usageStatOptions: (f = msg.getUsageStatOptions()) && proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.toObject(includeInstance, f),
    publicKeyIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadApiKeyRequest}
 */
proto.com.vectara.admin.ReadApiKeyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadApiKeyRequest;
  return proto.com.vectara.admin.ReadApiKeyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadApiKeyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadApiKeyRequest}
 */
proto.com.vectara.admin.ReadApiKeyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addKeyId(value);
      break;
    case 2:
      var value = new proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics;
      reader.readMessage(value,proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.deserializeBinaryFromReader);
      msg.setUsageStatOptions(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPublicKeyId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadApiKeyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadApiKeyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadApiKeyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadApiKeyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getUsageStatOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.serializeBinaryToWriter
    );
  }
  f = message.getPublicKeyIdList();
  if (f.length > 0) {
    writer.writePackedUint32(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    tsStart: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tsEnd: jspb.Message.getFieldWithDefault(msg, 2, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics}
 */
proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics;
  return proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics}
 */
proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTsStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTsEnd(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTsStart();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTsEnd();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDuration();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 ts_start = 1;
 * @return {number}
 */
proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.prototype.getTsStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics} returns this
 */
proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.prototype.setTsStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 ts_end = 2;
 * @return {number}
 */
proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.prototype.getTsEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics} returns this
 */
proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.prototype.setTsEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string duration = 3;
 * @return {string}
 */
proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.prototype.getDuration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics} returns this
 */
proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics.prototype.setDuration = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string key_id = 1;
 * @return {!Array<string>}
 */
proto.com.vectara.admin.ReadApiKeyRequest.prototype.getKeyIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.vectara.admin.ReadApiKeyRequest} returns this
 */
proto.com.vectara.admin.ReadApiKeyRequest.prototype.setKeyIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ReadApiKeyRequest} returns this
 */
proto.com.vectara.admin.ReadApiKeyRequest.prototype.addKeyId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadApiKeyRequest} returns this
 */
proto.com.vectara.admin.ReadApiKeyRequest.prototype.clearKeyIdList = function() {
  return this.setKeyIdList([]);
};


/**
 * optional UsageStatistics usage_stat_options = 2;
 * @return {?proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics}
 */
proto.com.vectara.admin.ReadApiKeyRequest.prototype.getUsageStatOptions = function() {
  return /** @type{?proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics, 2));
};


/**
 * @param {?proto.com.vectara.admin.ReadApiKeyRequest.UsageStatistics|undefined} value
 * @return {!proto.com.vectara.admin.ReadApiKeyRequest} returns this
*/
proto.com.vectara.admin.ReadApiKeyRequest.prototype.setUsageStatOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadApiKeyRequest} returns this
 */
proto.com.vectara.admin.ReadApiKeyRequest.prototype.clearUsageStatOptions = function() {
  return this.setUsageStatOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadApiKeyRequest.prototype.hasUsageStatOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated uint32 public_key_id = 3;
 * @return {!Array<number>}
 */
proto.com.vectara.admin.ReadApiKeyRequest.prototype.getPublicKeyIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.com.vectara.admin.ReadApiKeyRequest} returns this
 */
proto.com.vectara.admin.ReadApiKeyRequest.prototype.setPublicKeyIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ReadApiKeyRequest} returns this
 */
proto.com.vectara.admin.ReadApiKeyRequest.prototype.addPublicKeyId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadApiKeyRequest} returns this
 */
proto.com.vectara.admin.ReadApiKeyRequest.prototype.clearPublicKeyIdList = function() {
  return this.setPublicKeyIdList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ReadApiKeyResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadApiKeyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadApiKeyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadApiKeyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadApiKeyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiKeyDataList: jspb.Message.toObjectList(msg.getApiKeyDataList(),
    proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse}
 */
proto.com.vectara.admin.ReadApiKeyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadApiKeyResponse;
  return proto.com.vectara.admin.ReadApiKeyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadApiKeyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse}
 */
proto.com.vectara.admin.ReadApiKeyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData;
      reader.readMessage(value,proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.deserializeBinaryFromReader);
      msg.addApiKeyData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadApiKeyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadApiKeyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadApiKeyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadApiKeyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiKeyDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiKey: (f = msg.getApiKey()) && proto.com.vectara.admin.ApiKey.toObject(includeInstance, f),
    corpusList: jspb.Message.toObjectList(msg.getCorpusList(),
    proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData;
  return proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.ApiKey;
      reader.readMessage(value,proto.com.vectara.admin.ApiKey.deserializeBinaryFromReader);
      msg.setApiKey(value);
      break;
    case 2:
      var value = new proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus;
      reader.readMessage(value,proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.deserializeBinaryFromReader);
      msg.addCorpus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiKey();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.vectara.admin.ApiKey.serializeBinaryToWriter
    );
  }
  f = message.getCorpusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    servingUsageMetrics: (f = msg.getServingUsageMetrics()) && admin_admin_metric_pb.UsageMetricsResponse.toObject(includeInstance, f),
    indexingUsageMetrics: (f = msg.getIndexingUsageMetrics()) && admin_admin_metric_pb.UsageMetricsResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus;
  return proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new admin_admin_metric_pb.UsageMetricsResponse;
      reader.readMessage(value,admin_admin_metric_pb.UsageMetricsResponse.deserializeBinaryFromReader);
      msg.setServingUsageMetrics(value);
      break;
    case 4:
      var value = new admin_admin_metric_pb.UsageMetricsResponse;
      reader.readMessage(value,admin_admin_metric_pb.UsageMetricsResponse.deserializeBinaryFromReader);
      msg.setIndexingUsageMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getServingUsageMetrics();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      admin_admin_metric_pb.UsageMetricsResponse.serializeBinaryToWriter
    );
  }
  f = message.getIndexingUsageMetrics();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      admin_admin_metric_pb.UsageMetricsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus} returns this
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus} returns this
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UsageMetricsResponse serving_usage_metrics = 3;
 * @return {?proto.com.vectara.admin.UsageMetricsResponse}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.prototype.getServingUsageMetrics = function() {
  return /** @type{?proto.com.vectara.admin.UsageMetricsResponse} */ (
    jspb.Message.getWrapperField(this, admin_admin_metric_pb.UsageMetricsResponse, 3));
};


/**
 * @param {?proto.com.vectara.admin.UsageMetricsResponse|undefined} value
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus} returns this
*/
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.prototype.setServingUsageMetrics = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus} returns this
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.prototype.clearServingUsageMetrics = function() {
  return this.setServingUsageMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.prototype.hasServingUsageMetrics = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UsageMetricsResponse indexing_usage_metrics = 4;
 * @return {?proto.com.vectara.admin.UsageMetricsResponse}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.prototype.getIndexingUsageMetrics = function() {
  return /** @type{?proto.com.vectara.admin.UsageMetricsResponse} */ (
    jspb.Message.getWrapperField(this, admin_admin_metric_pb.UsageMetricsResponse, 4));
};


/**
 * @param {?proto.com.vectara.admin.UsageMetricsResponse|undefined} value
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus} returns this
*/
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.prototype.setIndexingUsageMetrics = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus} returns this
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.prototype.clearIndexingUsageMetrics = function() {
  return this.setIndexingUsageMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus.prototype.hasIndexingUsageMetrics = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ApiKey api_key = 1;
 * @return {?proto.com.vectara.admin.ApiKey}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.prototype.getApiKey = function() {
  return /** @type{?proto.com.vectara.admin.ApiKey} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.ApiKey, 1));
};


/**
 * @param {?proto.com.vectara.admin.ApiKey|undefined} value
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData} returns this
*/
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.prototype.setApiKey = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData} returns this
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.prototype.clearApiKey = function() {
  return this.setApiKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.prototype.hasApiKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ApiKeyCorpus corpus = 2;
 * @return {!Array<!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus>}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.prototype.getCorpusList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus, 2));
};


/**
 * @param {!Array<!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus>} value
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData} returns this
*/
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.prototype.setCorpusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus}
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.prototype.addCorpus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.ApiKeyCorpus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData} returns this
 */
proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData.prototype.clearCorpusList = function() {
  return this.setCorpusList([]);
};


/**
 * repeated ApiKeyData api_key_data = 1;
 * @return {!Array<!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData>}
 */
proto.com.vectara.admin.ReadApiKeyResponse.prototype.getApiKeyDataList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData>} value
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse} returns this
*/
proto.com.vectara.admin.ReadApiKeyResponse.prototype.setApiKeyDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData}
 */
proto.com.vectara.admin.ReadApiKeyResponse.prototype.addApiKeyData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.ReadApiKeyResponse.ApiKeyData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadApiKeyResponse} returns this
 */
proto.com.vectara.admin.ReadApiKeyResponse.prototype.clearApiKeyDataList = function() {
  return this.setApiKeyDataList([]);
};


/**
 * @enum {number}
 */
proto.com.vectara.admin.ApiKeyType = {
  API_KEY_TYPE__UNDEFINED: 0,
  API_KEY_TYPE__SERVING: 1,
  API_KEY_TYPE__SERVING_INDEXING: 2,
  API_KEY_TYPE__PERSONAL: 3
};

/**
 * @enum {number}
 */
proto.com.vectara.admin.ApiKeyStatus = {
  UNKNOWN: 0,
  ENABLED: 1,
  DISABLED: 2,
  DELETED: 3
};

goog.object.extend(exports, proto.com.vectara.admin);
