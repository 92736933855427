import {
  ListCorporaRequest,
  DeleteCorpusRequest,
  ResetCorpusRequest,
  UpdateCorpusEnablementRequest,
  ReadCorpusRequest,
  ComputeCorpusSizeRequest,
  ListCorporaResponse,
  ReadCorpusResponse,
  ComputeCorpusSizeResponse,
  DeleteCorpusResponse,
  FilterAttributeLevel,
  FilterAttributeType
} from "../generated_protos/admin/admin_corpus_pb";
import {
  HasCorpusPermissionsRequest,
  HasCorpusPermissionsResponse,
  HasPermissionsRequest,
  UserPermission
} from "../generated_protos/admin/admin_permission_pb";
import { Dimension, FilterAttribute } from "../generated_protos/admin/admin_corpus_pb";
import { AdminServicePromiseClient } from "../generated_protos/services_grpc_web_pb";
import { restServingUrl } from "../backendConfig";
import { apiV2Client } from "./apiV2Client";

export const ListCorpora = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string,
  filterText?: string,
  apiPageKey?: Uint8Array | string
): Promise<ListCorporaResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const listCorpusRequest = new ListCorporaRequest();
    if (filterText) {
      listCorpusRequest.setFilter(filterText);
    }
    if (apiPageKey) {
      listCorpusRequest.setPageKey(apiPageKey);
    }
    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };
    adminService
      .listCorpora(listCorpusRequest, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: listCorpora");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const HasPermissions = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string,
  permissions: UserPermission[]
) => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }
    const hasPermissionRequest = new HasPermissionsRequest();
    for (const i in permissions) {
      hasPermissionRequest.getPermissionList().push(permissions[i]);
    }

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };
    adminService
      .hasPermissions(hasPermissionRequest, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: hasPermissions");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const HasCorpusPermissions = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  corpusId: number,
  customerId: string,
  permissions: UserPermission[]
): Promise<HasCorpusPermissionsResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }
    const hasCorpusPermissionsRequest = new HasCorpusPermissionsRequest();
    hasCorpusPermissionsRequest.setCorpusId(corpusId);
    for (const i in permissions) {
      hasCorpusPermissionsRequest.getPermissionList().push(permissions[i]);
    }

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };
    adminService
      .hasCorpusPermissions(hasCorpusPermissionsRequest, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: hasCorpusPermissions");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const DeleteCorpus = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  corpusId: number,
  customerId: string
): Promise<DeleteCorpusResponse.AsObject | undefined> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new DeleteCorpusRequest();
    request.setCorpusId(corpusId);
    request.setCustomerId(Number(customerId));

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    adminService
      .deleteCorpus(request, args)
      .then((resp) => {
        if (resp.getStatus()?.getCode() === 0) {
          resolve(undefined);
        } else {
          reject(new Error(resp.getStatus()?.getStatusDetail()));
        }
      })
      .catch((reason) => reject(reason));
  });
};

export const ResetCorpus = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  corpusId: number,
  customerId: string
) => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ResetCorpusRequest();
    request.setCorpusId(corpusId);
    request.setCustomerId(Number(customerId));

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    // setTimeout(resolve, 5000);
    adminService
      .resetCorpus(request, args)
      .then((resp) => {
        if (resp.getStatus()?.getCode() === 0) {
          resolve(undefined);
        } else {
          reject(new Error(resp.getStatus()?.getStatusDetail()));
        }
      })
      .catch((reason) => reject(reason));
  });
};

export const UpdateCorpusEnablement = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  corpusId: number,
  customerId: string,
  isEnabled = false
) => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new UpdateCorpusEnablementRequest();
    request.setCorpusId(corpusId);
    request.setEnable(isEnabled);

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    adminService
      .updateCorpusEnablement(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from: UpdateCorpusEnablement");
          return;
        }
        resolve(respObj);
      })
      .catch((reason) => reject(reason));
  });
};

export const ReadCorpus = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  corpusId: string,
  customerId: string
): Promise<ReadCorpusResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ReadCorpusRequest();
    request.setReadBasicInfo(true);
    request.setReadRecall(true);
    request.setReadSize(true);
    request.setReadApiKeys(true);
    request.setReadCustomDimensions(true);
    request.setReadFilterAttributes(true);
    request.getCorpusIdList().push(+corpusId);

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    adminService
      .readCorpus(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from: ReadCorpus");
          return;
        }
        resolve(respObj);
      })
      .catch((reason) => reject(reason));
  });
};

export const ComputeCorpusSize = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  corpusId: number,
  customerId: string
): Promise<ComputeCorpusSizeResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ComputeCorpusSizeRequest();
    request.setCorpusId(corpusId);

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    adminService
      .computeCorpusSize(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from: ReadCorpus");
          return;
        }
        resolve(respObj);
      })
      .catch((reason) => reject(reason));
  });
};

export type CreateCorpusRequestConfig = {
  corpusKey: string;
  name: string;
  description: string;
  documentsAreQuestions: boolean;
  filterAttrs: FilterAttribute.AsObject[];
  customDims: Dimension.AsObject[];
  encoderId: number;
};

// TODO: We have to do this conversion because we're still depending on
// the FilterAttribute.Object type throughout the codebase. That type is
// generated from protobufs. Eventually we'll migrate to non-gRPC types
// and this won't be necessary.
const filterAttributeLevelToText = {
  [FilterAttributeLevel.FILTER_ATTRIBUTE_LEVEL__DOCUMENT]: "document",
  [FilterAttributeLevel.FILTER_ATTRIBUTE_LEVEL__DOCUMENT_PART]: "part"
} as const;

const filterAttributeTypeToText = {
  [FilterAttributeType.FILTER_ATTRIBUTE_TYPE__INTEGER]: "integer",
  [FilterAttributeType.FILTER_ATTRIBUTE_TYPE__INTEGER_LIST]: "list[integer]",
  [FilterAttributeType.FILTER_ATTRIBUTE_TYPE__REAL]: "real_number",
  [FilterAttributeType.FILTER_ATTRIBUTE_TYPE__REAL_LIST]: "list[real_number]",
  [FilterAttributeType.FILTER_ATTRIBUTE_TYPE__TEXT]: "text",
  [FilterAttributeType.FILTER_ATTRIBUTE_TYPE__TEXT_LIST]: "list[text]",
  [FilterAttributeType.FILTER_ATTRIBUTE_TYPE__BOOLEAN]: "boolean"
} as const;

export const generateCreateCorpusRequest = (
  jwt: string,
  customerId: string,
  { corpusKey, name, description, documentsAreQuestions, filterAttrs, customDims, encoderId }: CreateCorpusRequestConfig
) => {
  const body = {
    key: corpusKey,
    name,
    description,
    documents_are_questions: documentsAreQuestions,
    encoder_id: `enc_${encoderId}`,
    filter_attributes: filterAttrs.map(({ name, level, description, indexed, type }) => ({
      name,
      level: filterAttributeLevelToText[level as keyof typeof filterAttributeLevelToText],
      description,
      indexed,
      type: filterAttributeTypeToText[type as keyof typeof filterAttributeTypeToText]
    })),
    custom_dimensions: customDims.map(({ name, indexingDefault, servingDefault }) => ({
      name,
      indexing_default: indexingDefault,
      querying_default: servingDefault
    }))
  };

  const endpoint = "/v2/corpora";

  return {
    method: "POST",
    url: `${restServingUrl}${endpoint}`,
    endpoint,
    headers: {
      "customer-id": customerId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`
    },
    body
  } as const;
};

export type CreateCorpusRequest = ReturnType<typeof generateCreateCorpusRequest>;

export const sendCreateCorpusRequest = async (jwt: string, customerId: string, config: CreateCorpusRequestConfig) => {
  const { method, endpoint, headers, body } = generateCreateCorpusRequest(jwt, customerId, config);
  return await apiV2Client[method](endpoint, { headers, body });
};
