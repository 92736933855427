import { createContext, useContext, ReactNode, useState, useEffect } from "react";
import { BillingAddress, BillingDetail, Invoice, PaymentDetails } from "../generated_protos/admin/admin_billing_pb";
import { ReadUsageStatsResponse } from "../generated_protos/admin/admin_stats_pb";
import { ReadBillingPeriodDetails, ReadBillingData, ListInvoices } from "../admin/BillingEndpoint";
import { ReadUsageStats } from "../admin/UsageStatsEndpoint";
import { useUserContext } from "./UserContext";
import { useApiContext } from "./ApiContext";
import { ResourceAnalysis, ResourceType, analyzeUsage } from "./usage/analyzeUsage";
import { usePlanContext } from "./PlanContext";
import { getBundlesQuantity } from "../views/billing/usage/usagePeriod/getBundlesQuantity";
import { isFreeAndOverQuota } from "../views/billing/billingUtils";
import { usePaymentContext } from "./PaymentContext";

type BillingData = {
  billingAddress: BillingAddress.AsObject | undefined;
  creditCard: PaymentDetails.AsObject | undefined;
};

export type UsageAnalysis = Record<ResourceType, ResourceAnalysis>;
export interface UsageContextType {
  fetchBillingPeriodData: () => void;
  isLoadingBillingPeriodData: boolean;
  billingPeriodData?: BillingDetail.AsObject[];
  fetchBillingData: () => void;
  isLoadingBillingData: boolean;
  billingData?: BillingData;
  fetchUsageStats: () => void;
  isLoadingUsageStats: boolean;
  usageStats?: ReadUsageStatsResponse.AsObject;
  fetchInvoices: () => Promise<Invoice.AsObject[] | undefined>;
  bundlesQuantity: number | undefined;
  usageAnalysis: UsageAnalysis | undefined;
  isOverQuota: boolean;
  isLimited: boolean;
}

const UsageContext = createContext<UsageContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const UsageContextProvider = ({ children }: Props) => {
  const { AdminService } = useApiContext();
  const { getJwt, customer } = useUserContext();
  const { currentPlan, isOnFreePlan } = usePlanContext();
  const { hasPaymentSource } = usePaymentContext();

  const [isLoadingBillingPeriodData, setIsLoadingBillingPeriodData] = useState(false);
  const [billingPeriodData, setBillingPeriodData] = useState<BillingDetail.AsObject[] | undefined>(undefined);
  const [isLoadingBillingData, setIsLoadingBillingData] = useState(false);
  const [billingData, setBillingData] = useState<BillingData | undefined>(undefined);
  const [isLoadingUsageStats, setIsLoadingUsageStats] = useState(false);
  const [usageStats, setUsageStats] = useState<ReadUsageStatsResponse.AsObject | undefined>(undefined);

  const { customerId } = customer ?? {};

  useEffect(() => {
    fetchUsageStats();
  }, []);

  const fetchBillingPeriodData = async () => {
    if (!customerId) return;
    setIsLoadingBillingPeriodData(true);
    try {
      const jwt = await getJwt();
      const result = await ReadBillingPeriodDetails(jwt, AdminService, customerId);
      setBillingPeriodData(result.billingDetailList);
      setIsLoadingBillingPeriodData(false);
    } catch (e) {
      console.log("Billing period data error", e);
    }
  };

  const fetchBillingData = async () => {
    if (!customerId) return;
    setIsLoadingBillingData(true);
    try {
      const jwt = await getJwt();
      const result = await ReadBillingData(jwt, AdminService, customerId);
      setBillingData({
        billingAddress: result.billingAddress,
        creditCard: result.paymentDetails
      });
      setIsLoadingBillingData(false);
    } catch (e) {
      console.log("Billing data error", e);
    }
  };

  const fetchUsageStats = async () => {
    if (!customerId) return;
    setIsLoadingUsageStats(true);
    try {
      const jwt = await getJwt();
      const result = await ReadUsageStats(jwt, AdminService, customerId);
      setUsageStats(result);
      setIsLoadingUsageStats(false);
    } catch (e) {
      console.log("Usage stats error", e);
    }
  };

  const fetchInvoices = async () => {
    if (!customerId) return;
    try {
      const jwt = await getJwt();
      const result = await ListInvoices(jwt, AdminService, customerId);
      return result.invoicesList;
    } catch (e) {
      console.log("Fetch invoices error", e);
    }
  };

  const bundlesQuantity = billingPeriodData ? getBundlesQuantity(billingPeriodData) : 0;
  const usageAnalysis = analyzeUsage(bundlesQuantity, currentPlan, usageStats);
  const isOverQuota = isFreeAndOverQuota(isOnFreePlan, usageStats);
  const isLimited = isOverQuota && !hasPaymentSource;

  return (
    <UsageContext.Provider
      value={{
        fetchBillingPeriodData,
        isLoadingBillingPeriodData,
        billingPeriodData,
        fetchBillingData,
        isLoadingBillingData,
        billingData,
        fetchUsageStats,
        isLoadingUsageStats,
        usageStats,
        fetchInvoices,
        bundlesQuantity,
        usageAnalysis,
        isOverQuota,
        isLimited
      }}
    >
      {children}
    </UsageContext.Provider>
  );
};

export const useUsageContext = () => {
  const context = useContext(UsageContext);
  if (context === undefined) {
    throw new Error("useUsageContext must be used within a UsageContextProvider");
  }
  return context;
};
