export const humanizeEpochSeconds = (epochSeconds: number) => {
  const date = new Date(epochSeconds * 1000);
  // Convert to UTC 00:00.
  const dateUtc = date.getTime() + date.getTimezoneOffset() * 60000;
  return new Date(dateUtc).toLocaleString();
};

export const differenceInMinutes = (expiryDateTime: Date, currentDateTime: Date) => {
  const timeToExpiry = expiryDateTime.getTime() - currentDateTime.getTime();
  const timeToExpiryInMinutes = Math.floor((timeToExpiry % (1000 * 60 * 60)) / (1000 * 60));
  return timeToExpiryInMinutes;
}