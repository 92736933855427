import { Job, JobState, JobType } from "../../generated_protos/admin/admin_job_pb";
import { CorpusJobs } from "./buildJobsMap";

// Jobs that are active have the highest priority, followed by
// those that the user can act upon. The ones that the user
// can't act upon are last.
const prioritizedJobStates = [
  JobState.JOB_STATE__STARTED, // Active
  JobState.JOB_STATE__TRANSIENT_FAILURE_RETRY_IMMINENT, // Active
  JobState.JOB_STATE__ABORTED, // Active, because the system aborted it and might retry it
  JobState.JOB_STATE__FAILED, // Actionable by retrying
  JobState.JOB_STATE__QUEUED, // Can't do anything
  JobState.JOB_STATE__UNKNOWN, // Can't do anything
  JobState.JOB_STATE__COMPLETED // Doesn't matter any more
];

export const sortJobsByPriority = (corpusJobs: Partial<CorpusJobs>) => {
  let flattenedJobs: Job.AsObject[] = [];

  // https://stackoverflow.com/questions/52856496/typescript-object-keys-return-string
  (Object.keys(corpusJobs) as unknown as Array<JobType>).forEach((jobType) => {
    const jobs = corpusJobs[jobType];
    if (jobs) {
      flattenedJobs = flattenedJobs.concat(jobs);
    }
  });

  return flattenedJobs.sort((a, b) => {
    const aPriority = prioritizedJobStates.indexOf(a.state);
    const bPriority = prioritizedJobStates.indexOf(b.state);

    if (aPriority < bPriority) {
      return -1;
    }

    if (aPriority > bPriority) {
      return 1;
    }

    return 0;
  });
};
