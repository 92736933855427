type IsInvitation = "true" | null;
type IsRecovery = "true" | null;

const CUSTOMER_ID = "c";
const USER_NAME = "username";
const CORPUS_ID = "corpusId";
const QUERY = "query";

export const extractUserName = (searchParams: URLSearchParams) => {
  return searchParams.get(USER_NAME);
};

export const insertUserName = (userName: string | undefined, searchParams: URLSearchParams) => {
  if (userName) {
    searchParams.set(USER_NAME, userName);
  }

  return searchParams;
};

export const extractCustomerId = (searchParams: URLSearchParams) => {
  return searchParams.get(CUSTOMER_ID);
};

export const insertCustomerId = (customerId: string | undefined, searchParams: URLSearchParams) => {
  if (customerId) {
    searchParams.set(CUSTOMER_ID, customerId);
  }

  return searchParams;
};

export const extactSuggestedEmailId = (searchParams: URLSearchParams) => {
  return searchParams.get("id") ? decodeURIComponent(searchParams.get("id") as string) : undefined;
};

export const extractVerificationCode = (searchParams: URLSearchParams) => {
  return searchParams.get("code");
};

export const extractIsInvitation = (searchParams: URLSearchParams) => {
  const isInvitation: IsInvitation = searchParams.get("invite") as IsInvitation;
  return isInvitation === "true";
};

export const extractIsRecovery = (searchParams: URLSearchParams) => {
  const isRecovery: IsRecovery = searchParams.get("recover") as IsRecovery;
  return isRecovery === "true";
};

export const extractCreateApiKey = (searchParams: URLSearchParams) => {
  const corpusName: string | null = searchParams.get("corpusName");
  const corpusId: string | null = searchParams.get("corpusId");
  return { corpusName, corpusId };
};

export const extractAwsSubscriptionToken = (searchParamsString: string) => {
  // We roll our own search params logic here because URLSearchParams drops + signs:
  // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#preserving_plus_signs.
  // It is hard for us to encode the token correctly upstream because Java doesn't have a
  // native equivalent to encodeURIComponent. So we'll just preserve these characters on
  // the client, but without using URLSearchParams.
  const searchParams = (searchParamsString.split("?")[1] ?? "").split("&");

  for (const param of searchParams) {
    if (param.startsWith("awsToken=")) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [key, value] = param.split("awsToken=");
      return value;
    }
  }

  return undefined;
};

export const extractCorpusId = (searchParams: URLSearchParams) => {
  return searchParams.get(CORPUS_ID);
};

export const extractQuery = (searchParams: URLSearchParams) => {
  return searchParams.get(QUERY);
};
