// Third-party properties.
export const awsMarketplaceConsoleUrl = "https://console.aws.amazon.com/marketplace/home";

// Our properties.
export const marketingSiteUrl = "https://www.vectara.com";
export const docsUrl = "https://docs.vectara.com";
export const forumsUrl = "https://discuss.vectara.com";
export const forumsHelpUrl = "https://discuss.vectara.com/c/q-and-a/6";
export const supportPortalUrl = "https://support.vectara.com";
export const pricingPageUrl = "https://vectara.com/pricing/";
export const customerAgreementUrl = "https://vectara.com/legal/online-customer-agreement";
export const privacyPolicyUrl = "https://vectara.com/legal/privacy-policy";
export const emailFeedbackUrl = "mailto:feedback@vectara.com";
export const contactSalesUrl = "https://vectara.com/contact-us"; // Accepts "email" query parameter.
export const discordUrl = "https://discord.gg/GFb8gMz6UH";
export const statusPageUrl = "https://status.vectara.com/";

export const repos = {
  vectaraAnswerUrl: "https://github.com/vectara/vectara-answer",
  vectaraAnswerDemo: "https://asknews.demo.vectara.com/",
  gettingStarted: "https://github.com/vectara/getting-started",
  reactChatbot: "https://github.com/vectara/react-chatbot",
  reactChatbotDemo: "https://vectara.github.io/react-chatbot",
  reactSearch: "https://github.com/vectara/react-search",
  reactSearchDemo: "https://vectara.github.io/react-search",
  createUi: "https://github.com/vectara/create-ui",
  createUiDemo: "https://vectara.github.io/create-ui",
  streamQueryClient: "https://github.com/vectara/stream-query-client",
  jsWidgets: "https://github.com/vectara/js-widgets"
};

export const blog = {
  airbyte: "https://vectara.com/blog/vectara-and-airbyte/"
};

export const docs = {
  platformOverview: "https://docs.vectara.com/docs/",
  apiRecipes: "https://docs.vectara.com/docs/api-recipes",
  quickStart: "https://docs.vectara.com/docs/quickstart",
  quickStartCreateIndex: "https://docs.vectara.com/docs/quickstart#create-your-first-index",
  dataIngestion: "https://docs.vectara.com/docs/learn/data-ingestion",
  groundedGeneration:
    "https://docs.vectara.com/docs/common-use-cases/chatbots-grounded-generation/grounded-generation-overview",
  reranking: "https://docs.vectara.com/docs/search-apis/reranking",
  authorization: "https://docs.vectara.com/docs/common-use-cases/app-authn-authz/authorization",
  apiOverview: "https://docs.vectara.com/docs/api-reference/api-overview",
  protoBufs: "https://docs.vectara.com/docs/api-reference/protobuf-definitions",
  apiKeys: "https://docs.vectara.com/docs/common-use-cases/app-authn-authz/api-keys",
  apiPlayground: "https://docs.vectara.com/docs/rest-api/",
  restApiQuery: "https://docs.vectara.com/docs/rest-api/query",
  restApiIndex: "https://docs.vectara.com/docs/rest-api/index",
  restApiDelete: "https://docs.vectara.com/docs/rest-api/delete",
  indexing: "https://docs.vectara.com/docs/api-reference/indexing-apis/indexing",
  indexDocument: "https://docs.vectara.com/docs/api-reference/indexing-apis/indexing#index-document",
  jsonFormat: "https://docs.vectara.com/docs/indexing-apis/format-for-upload",
  fileUpload: "https://docs.vectara.com/docs/indexing-apis/file-upload",
  fileUploadFileTypes: "https://docs.vectara.com/docs/indexing-apis/file-upload-filetypes",
  textlessMode: "https://docs.vectara.com/docs/textless",
  oauth2Url: "https://docs.vectara.com/docs/api-reference/auth-apis/oauth-2",
  personalApiKey: "https://docs.vectara.com/docs/console-ui/personal-api-key",
  createCorpus: "https://docs.vectara.com/docs/console-ui/creating-a-corpus",
  customDimensions: "https://docs.vectara.com/docs/custom-dimensions",
  relevanceTuning: "https://docs.vectara.com/docs/learn/semantic-search/relevance-tuning-techniques",
  filterAttributes: "https://docs.vectara.com/docs/api-reference/admin-apis/create-corpus#filter-attribute",
  filtering: "https://docs.vectara.com/docs/search-apis/sql/filter-overview",
  filterSyntax: "https://docs.vectara.com/docs/api-reference/search-apis/sql/func-opr",
  lexicalMatching: "https://docs.vectara.com/docs/search-apis/lexical-matching",
  documentStructure: "https://docs.vectara.com/docs/learn/document-data-structuring",
  chatManagement: "https://docs.vectara.com/docs/console-ui/vectara-chat-overview",
  chat: "https://docs.vectara.com/docs/console-ui/chat-with-your-data",
  evaluation: "https://docs.vectara.com/docs/console-ui",
  summarizers: "https://docs.vectara.com/docs/learn/grounded-generation/select-a-summarizer",
  query: "https://docs.vectara.com/docs/api-reference/search-apis/search",
  fcs: "https://docs.vectara.com/docs/api-reference/search-apis/search?#factual-consistency-score",
  streamQuery: "https://docs.vectara.com/docs/api-reference/search-apis/stream-query",
  promptEngine: "https://docs.vectara.com/docs/prompts/vectara-prompt-engine",
  udfReranker: "https://docs.vectara.com/docs/learn/user-defined-function-reranker"
};
