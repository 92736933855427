import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiX } from "react-icons/bi";
import {
  VuiAppContent,
  VuiAppLayout,
  VuiButtonTertiary,
  VuiFlexContainer,
  VuiIcon,
  VuiSpacer,
  VuiText,
  VuiTextColor
} from "@vectara/vectara-ui";
import { useSurveyContext } from "../../contexts/SurveyContext";
import { surveySchema } from "./surveySchema";
import { Question } from "./Question";
import { OptionType, SurveyAnswers } from "./types";
import { useLayoutContext } from "../../contexts/LayoutContext";
import { CORPORA_PATH } from "../../utils/paths";
import "./_index.scss";

export const OnboardingSurvey = () => {
  const {
    currentQuestion,
    currentQuestionIndex,
    totalQuestions,
    hasNextQuestion,
    hasPreviousQuestion,
    goToNextQuestion,
    goToPreviousQuestion,
    completeSurvey,
    skipSurvey
  } = useSurveyContext();
  const { isSurveyActive } = useSurveyContext();
  const { setIsNavPinnedOpen } = useLayoutContext();
  const navigate = useNavigate();

  const [answers, setAnswers] = useState<SurveyAnswers>(
    surveySchema.reduce((acc, schema) => {
      acc[schema.id] = {
        selection: "",
        customAnswer: schema.type === "radioCustom" ? "" : undefined
      };
      return acc;
    }, {} as SurveyAnswers)
  );

  useEffect(() => {
    if (!isSurveyActive) {
      navigate(`/console/${CORPORA_PATH}`);
    }
  }, []);

  const exitSurvey = () => {
    setIsNavPinnedOpen(true);
    navigate(`/console/${CORPORA_PATH}`);
  };

  return (
    <VuiAppLayout>
      <VuiAppContent padding="xl" fullWidth className="onboardingSurveyContainer">
        <VuiFlexContainer
          alignItems="stretch"
          justifyContent="spaceBetween"
          direction="column"
          className="onboardingSurvey"
        >
          <VuiFlexContainer alignItems="center" justifyContent="end">
            <VuiButtonTertiary
              color="subdued"
              size="s"
              icon={
                <VuiIcon>
                  <BiX />
                </VuiIcon>
              }
              iconSide="left"
              data-testid="skipSurveyButton"
              // Open the nav whenever the wizard is dismissed because we want to expose users
              // to all of the nav options as part of the onboarding experience.
              onClick={() => {
                skipSurvey();
                exitSurvey();
              }}
            >
              Skip
            </VuiButtonTertiary>
          </VuiFlexContainer>

          <VuiFlexContainer alignItems="center" direction="column">
            <VuiSpacer size="l" />

            <div className="onboardingSurveyContent">
              {currentQuestionIndex === 0 && (
                <>
                  <VuiText size="l">
                    <p>
                      <VuiTextColor color="subdued">
                        Please tell us about yourself so we can improve Vectara for you!
                      </VuiTextColor>
                    </p>
                  </VuiText>

                  <VuiSpacer size="xxl" />
                </>
              )}

              <VuiText>
                <p>
                  <VuiTextColor color="subdued">
                    Question {currentQuestionIndex + 1} of {totalQuestions}
                  </VuiTextColor>
                </p>
              </VuiText>

              <VuiSpacer size="xs" />

              <Question
                /* Use key to hard-rerender when the currentQuestion changes */
                key={currentQuestion.id}
                schema={currentQuestion}
                answer={answers[currentQuestion.id]}
                onSelectAnswer={(option?: OptionType) => {
                  if (!option) return;

                  setAnswers((answers) => ({
                    ...answers,
                    [currentQuestion.id]: {
                      ...answers[currentQuestion.id],
                      selection: option.value
                    }
                  }));

                  if (option.type !== "custom" && hasNextQuestion) {
                    goToNextQuestion();
                  }
                }}
                onChangeCustomerAnswer={
                  currentQuestion.type === "radioCustom"
                    ? (value: string) => {
                        setAnswers((answers) => {
                          return {
                            ...answers,
                            [currentQuestion.id]: {
                              ...answers[currentQuestion.id],
                              customAnswer: value
                            }
                          };
                        });
                      }
                    : undefined
                }
                onGoToNextQuestion={hasNextQuestion ? goToNextQuestion : undefined}
                onGoToPreviousQuestion={hasPreviousQuestion ? goToPreviousQuestion : undefined}
                onComplete={
                  !hasNextQuestion
                    ? () => {
                        completeSurvey(answers);
                        exitSurvey();
                      }
                    : undefined
                }
              />
            </div>
          </VuiFlexContainer>

          {/* Force flex layout to vertically center the form. */}
          <VuiSpacer size="xxl" />
        </VuiFlexContainer>
      </VuiAppContent>
    </VuiAppLayout>
  );
};
