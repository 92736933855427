import { createContext, useContext, ReactNode, useState } from "react";
import { ListJobs } from "../admin/JobsEndpoint";
import { useUserContext } from "./UserContext";
import { useApiContext } from "./ApiContext";
import { Job, JobState, JobType } from "../generated_protos/admin/admin_job_pb";
import { updateJobs } from "./jobs/updateJobs";
import { CorpusJobs, buildJobsMap } from "./jobs/buildJobsMap";
import { sortJobsByPriority } from "./jobs/sortJobsByPriority";
import { getActiveReplaceFilterJobs } from "./jobs/getActiveReplaceFilterJobs";

interface JobsContextType {
  isLoadingJobs: boolean;
  jobs: Job.AsObject[];
  loadJobs: (corpusIds: number[] | number) => Promise<void>;
  getJobsForCorpus: (corpusId: number) => Job.AsObject[] | undefined;
  getJobForCorpus: (corpusId: number) => Job.AsObject | undefined;
  getCanEditFilterAttributes: (corpusId: number) => boolean;
  activeReplaceFilterJobs: Job.AsObject[];
}

const JobsContext = createContext<JobsContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const JobsContextProvider = ({ children }: Props) => {
  const { getJwt, customer } = useUserContext();
  const { AdminService } = useApiContext();
  const [isLoadingJobs, setIsLoadingJobs] = useState(false);
  const [jobsMap, setJobsMap] = useState<Record<number, CorpusJobs>>({});
  const [jobs, setJobs] = useState<Job.AsObject[]>([]);

  // TODO: Define array of corpus IDs so Corpora.tsx can fetch
  // a single page's worth of jobs.
  const loadJobs = async (corpusIds: number[] | number) => {
    if (!customer) return;
    try {
      setIsLoadingJobs(true);
      const jwt = await getJwt();
      const ids = Array.isArray(corpusIds) ? corpusIds : [corpusIds];
      const result = await ListJobs(jwt, AdminService, customer.customerId, ids);

      setIsLoadingJobs(false);
      const newJobs = updateJobs(jobs, result.jobList, ids);
      setJobs(newJobs);

      // Naively rebuilding the map every time we load the job is inefficient but
      // it's simple. We should optimize this if it turns out to be a botteneck.
      setJobsMap(buildJobsMap(newJobs));
    } catch (err) {
      setIsLoadingJobs(false);
      console.log(err);
    }
  };

  const getJobsForCorpus = (corpusId: number) => {
    const corpusJobs = jobsMap[corpusId];
    // Order them by priority so the first one is most important to the user.
    return corpusJobs ? sortJobsByPriority(corpusJobs) : undefined;
  };

  const getJobForCorpus = (corpusId: number) => {
    // Get the most important job for the corpus.
    const jobs = getJobsForCorpus(corpusId);
    return jobs ? jobs[0] : undefined;
  };

  const getCanEditFilterAttributes = (corpusId: number) => {
    const RETRYABLE_JOB_STATES = [
      JobState.JOB_STATE__ABORTED,
      JobState.JOB_STATE__COMPLETED,
      JobState.JOB_STATE__FAILED,
      JobState.JOB_STATE__TRANSIENT_FAILURE_RETRY_IMMINENT,
      JobState.JOB_STATE__UNKNOWN
    ];

    const job = getJobForCorpus(corpusId);

    return job?.type !== JobType.JOB__CORPUS_REPLACE_FILTER_ATTRS || RETRYABLE_JOB_STATES.includes(job.state);
  };

  const activeReplaceFilterJobs = getActiveReplaceFilterJobs(jobs);

  return (
    <JobsContext.Provider
      value={{
        isLoadingJobs,
        jobs,
        loadJobs,
        getJobsForCorpus,
        getJobForCorpus,
        getCanEditFilterAttributes,
        activeReplaceFilterJobs
      }}
    >
      {children}
    </JobsContext.Provider>
  );
};

export const useJobsContext = () => {
  const context = useContext(JobsContext);
  if (context === undefined) {
    throw new Error("useJobsContext must be used within a JobsContextProvider");
  }
  return context;
};
