import { createContext, useContext, ReactNode, useState, useEffect } from "react";
import { PaymentMethod } from "@stripe/stripe-js";
import { StatusCode } from "../generated_protos/status_pb";
import { UpdatePaymentDetails } from "../admin/BillingEndpoint";
import { HasDefaultPaymentSource } from "../admin/BillingEndpoint";
import { usePlanContext } from "./PlanContext";
import { useUserContext } from "./UserContext";
import { useApiContext } from "./ApiContext";
import { useNotificationsContext } from "./NotificationsContext";

type PaymentModalMode = "upsellToGrowth" | "edit";

interface PaymentContextType {
  isPaymentModalOpen: boolean;
  paymentModalMode: PaymentModalMode | undefined;
  setIsPaymentModalOpen: (mode: PaymentModalMode | false) => void;
  hasPaymentSource?: boolean;
  updatePaymentSource: (paymentSource: PaymentMethod) => void;
  isUpdatingPaymentSource: boolean;
  isLoadingPaymentSource: boolean;
}

const PaymentContext = createContext<PaymentContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const PaymentContextProvider = ({ children }: Props) => {
  const { getJwt, customer } = useUserContext();
  const { AdminService } = useApiContext();
  const { switchPlan, growthCCPlanId } = usePlanContext();
  const { addNotification } = useNotificationsContext();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [paymentModalMode, setPaymentModalMode] = useState<PaymentModalMode>();
  const [isLoadingPaymentSource, setIsLoadingPaymentSource] = useState(false);
  const [isUpdatingPaymentSource, setIsUpdatingPaymentSource] = useState(false);
  const [hasPaymentSource, setHasPaymentSource] = useState<boolean>();

  useEffect(() => {
    // TOOD: The user missing required privileges won't have a plan.
    checkPaymentSource();
  }, [customer]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkPaymentSource = async () => {
    if (!customer) return;

    const jwt = await getJwt();
    setIsLoadingPaymentSource(true);

    HasDefaultPaymentSource(jwt, AdminService, customer.customerId)
      .then((result) => {
        setIsLoadingPaymentSource(false);
        setHasPaymentSource(result?.status?.code === StatusCode.OK);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingPaymentSource(false);
        setHasPaymentSource(undefined);
      });
  };

  const updatePaymentSource = async (paymentSource: PaymentMethod) => {
    if (!customer) return;

    const jwt = await getJwt();
    setIsUpdatingPaymentSource(true);

    UpdatePaymentDetails(jwt, AdminService, customer.customerId, paymentSource.id)
      .then((status) => {
        setIsUpdatingPaymentSource(false);
        setIsPaymentModalOpen(false);
        if (status.status?.code === StatusCode.OK) {
          addNotification("Payment method updated successfully", "success");
          // As soon as a valid payment method is added, plan switches from Free to either Growth
          // or Scale. For now this is hardcoded to Growth because you can't sign up for Scale
          // through the UI yet.
          switchPlan(growthCCPlanId, "Growth");
          setHasPaymentSource(true);
        } else {
          if (status.status) addNotification(status.status.statusDetail, "danger");
        }
      })
      .catch((err) => {
        setIsUpdatingPaymentSource(false);
        setIsPaymentModalOpen(false);
        addNotification(`Error while updating payment method ${err}`, "danger");
        console.log(err);
      });
  };

  return (
    <PaymentContext.Provider
      value={{
        isPaymentModalOpen,
        paymentModalMode,
        setIsPaymentModalOpen: (mode: PaymentModalMode | false) => {
          if (mode === "upsellToGrowth") {
            setIsPaymentModalOpen(true);
            setPaymentModalMode("upsellToGrowth");
            return;
          }

          if (mode === "edit") {
            setIsPaymentModalOpen(true);
            setPaymentModalMode("edit");
            return;
          }

          setIsPaymentModalOpen(false);
          setPaymentModalMode(undefined);
        },
        isLoadingPaymentSource,
        hasPaymentSource,
        updatePaymentSource,
        isUpdatingPaymentSource
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export const usePaymentContext = () => {
  const context = useContext(PaymentContext);
  if (context === undefined) {
    throw new Error("usePaymentContext must be used within a PaymentContextProvider");
  }
  return context;
};
