import { useEffect } from "react";
import {
  VuiButtonSecondary,
  VuiFlexContainer,
  VuiFlexItem,
  VuiIcon,
  VuiSpacer,
  VuiText,
  VuiTextColor
} from "@vectara/vectara-ui";
import { StatusCode } from "../../../../generated_protos/status_pb";
import { formatBytes } from "../../utilityFunctions";
import { useUserContext } from "../../../../contexts/UserContext";
import { BiError } from "react-icons/bi";
import { useAccountSizeContext } from "../../../../contexts/AccountSizeContext";

export const AccountSize = () => {
  const { customer, userDetails, abilities } = useUserContext();
  const {
    computeAccountSize,
    readAccountSize,
    accountSize,
    accountMetadataSize,
    accountSizeStatus,
    accountSizeError,
    isLoadingAccountSize
  } = useAccountSizeContext();

  useEffect(() => {
    const componentState = { isMounted: true };
    if (customer && abilities?.canReadAccountSize) {
      readAccountSize(componentState);
    }
    return () => {
      componentState.isMounted = false;
    };
  }, [customer]); // eslint-disable-line react-hooks/exhaustive-deps

  const accountSizeFormatted =
    accountSizeStatus === StatusCode.NOT_FOUND ? "-" : formatBytes((accountSize ?? 0) + (accountMetadataSize ?? 0));

  const textSize = formatBytes(accountSize ?? 0);
  const metadataSize = formatBytes(accountMetadataSize ?? 0);

  return (
    <>
      {!accountSizeError && (
        <>
          Account size: {accountSizeFormatted}
          <VuiText size="xs">
            <p>
              <VuiTextColor color="subdued">Text: {textSize}</VuiTextColor>
            </p>
          </VuiText>
          <VuiText size="xs">
            <p>
              <VuiTextColor color="subdued">Metadata: {metadataSize}</VuiTextColor>
            </p>
          </VuiText>
        </>
      )}

      {accountSizeError && (
        <VuiFlexContainer alignItems="center" spacing="xs">
          <VuiFlexItem grow={false} shrink={false}>
            <VuiIcon color="danger">
              <BiError />
            </VuiIcon>
          </VuiFlexItem>

          <VuiFlexItem grow={1}>
            <VuiText>
              <p>
                <VuiTextColor color="danger">
                  <strong>Error:</strong> {accountSizeError}
                </VuiTextColor>
              </p>
            </VuiText>
          </VuiFlexItem>
        </VuiFlexContainer>
      )}

      <VuiSpacer size="xs" />

      <VuiButtonSecondary
        size="xs"
        color="neutral"
        onClick={computeAccountSize}
        isDisabled={
          !userDetails?.userCredentials?.isOwner &&
          !userDetails?.userCredentials?.isAdmin &&
          !userDetails?.userCredentials?.isBillingAdmin &&
          !userDetails?.userCredentials?.isCorpusAdmin
        }
      >
        {isLoadingAccountSize ? "Computing…" : "Compute"}
      </VuiButtonSecondary>
    </>
  );
};
