import { GenerationPreset } from "../../admin/apiV2Client";
import { CustomDimension } from "../../generated_protos/serving_pb";
import { QueryMode } from "../ConfigContext";

export type CorpusSettings = {
  queryMode?: QueryMode;
  lambda?: number;
  isSummarizationEnabled?: boolean;
  isChatEnabled?: boolean;
  isFactualConsistencyScoreEnabled?: boolean;
  isRerankingEnabled?: boolean;
  rerankerId?: number;
  diversityAmount?: number;
  rerankAmount?: number;
  customDimensions?: CustomDimension.AsObject[];
  summarizerName?: string; // Deprecated for generationPresetName
  llmName?: string;
  generationPresetName?: string;
  summaryLanguage?: string;
  maxSummarizedResults?: number;
  customPrompt?: string;
  sentencesBefore?: number;
  sentencesAfter?: number;
  charsBefore?: number;
  charsAfter?: number;
  rerankerFunction?: string;
};

export type Config = {
  corporaSettings?: Record<number, CorpusSettings>;
  isSurveyComplete?: boolean;
  // This state tracks the user's progress through the dedicated onboarding wizard.
  // It was deprecated in #2277 but some customers still have it in their config and we need to use
  // this to prevent the survey from popping up again.
  onboardingWizard?: {
    isDismissed?: boolean;
  };
  isSearchSummaryEnabled?: boolean;
  // IDs of announcements that have already been read, stored as an object for more efficient lookup.
  readAnnouncementIds?: Record<string, boolean>;
  // Determines if code samples in the request tab should use streaming or not
  isStreamingEnabledInSnippet?: boolean;
};

export const migrateConfig = (
  config: Config | undefined,
  {
    generationPresets
  }: {
    generationPresets?: GenerationPreset[];
  }
) => {
  if (!config) return config;

  migrateFromOnboardingWizardToSurvey(config);
  migrateFromSummarizerToGenerationPreset(config, generationPresets);

  return config;
};

const migrateFromOnboardingWizardToSurvey = (config: Config) => {
  const { onboardingWizard } = config;
  if (!onboardingWizard) return;

  // The onboarding wizard was deprecated in #2277 but some customers still have it in their config.
  // If they dismissed the wizard then it's a proxy for skipping the survey.
  const { isDismissed } = onboardingWizard;
  if (isDismissed) {
    config.isSurveyComplete = true;
  }

  delete config.onboardingWizard;
};

const migrateFromSummarizerToGenerationPreset = (config: Config, generationPresets?: GenerationPreset[]) => {
  const { corporaSettings } = config;
  if (!corporaSettings) return;

  Object.values(corporaSettings).forEach((corpusSettings) => {
    // If there's a summarizer, then try to convert it into a generation preset.
    const deprecatedSummarizerName = corpusSettings.summarizerName;
    if (
      deprecatedSummarizerName &&
      generationPresets?.find((generationPreset) => generationPreset.name === deprecatedSummarizerName)
    ) {
      corpusSettings.generationPresetName = deprecatedSummarizerName;
      delete corpusSettings.summarizerName;
    }
  });
};
