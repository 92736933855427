import createClient from "openapi-fetch";
import type { paths, components } from "./apiv2";
import { restServingUrl } from "../backendConfig";

// See docs at https://github.com/drwpow/openapi-typescript/tree/main/packages/openapi-fetch
export const apiV2Client = createClient<paths>({ baseUrl: restServingUrl });

type ApiV2 = components["schemas"];
export type { ApiV2 };

export type GenerationPreset = ApiV2["GenerationPreset"];
export type Llm = ApiV2["LLM"];
