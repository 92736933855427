import { Auth } from "aws-amplify";
import { Amplify } from "@aws-amplify/core";
import { setAuthMechanism } from "../localStorage/customer";
import { AwsConfig } from "../../types/user";
import { PublicAdminServicePromiseClient } from "../../generated_protos/services_grpc_web_pb";
import { GetCustomerLoginAttribs } from "../../admin/PublicEndpoint";
import { debug } from "../debug";
import { orySdk } from "./orySdk";
import { CustomError } from "../customError";
import { refreshOrySession } from "./refreshJwt";
import { ORY_POOL_PREFIX } from "../../backendConfig";

const configureAmplify = (awsConfig: AwsConfig) => {
  const { awsRegion, userPoolId, userPoolWebClientId } = awsConfig;

  // This region is dynamic.
  const config = {
    // Set a scope configuration.
    // https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#scoped-configuration
    Auth: {
      region: awsRegion,
      userPoolId: userPoolId,
      userPoolWebClientId: userPoolWebClientId,
      authenticationFlowType: "USER_SRP_AUTH",
      mandatorySignIn: false
    }
  };

  Amplify.configure(config);
};

export const authenticateWithPassword = async (isOryAuth: boolean, awsConfig: AwsConfig) => {
  if (isOryAuth) {
    return await refreshOrySession();
  } else {
    configureAmplify(awsConfig);
    return await Auth.currentAuthenticatedUser();
  }
};

const createOryLoginFlow = async () => {
  try {
    const response = await orySdk.createBrowserLoginFlow();

    if (!response.data) {
      throw new Error("Failed to create Login Flow");
    }

    if (response.data) {
      let csrf_token;
      const flowId = response.data.id;
      response.data.ui.nodes.forEach((v) => {
        const attr: any = v.attributes;
        if (attr.name === "csrf_token") {
          csrf_token = attr.value;
          return;
        }
      });

      return { flowId, csrfToken: csrf_token };
    }

    throw new Error("failed to create login flow");
  } catch (e) {
    debug("createLoginFlow failure", e);
    throw e;
  }
};
const loginWithOry = async (email: string, password: string, customerId: number, flowId: string, csrfToken: string) => {
  try {
    const response = await orySdk.updateLoginFlow({
      flow: flowId,
      updateLoginFlowBody: {
        password: password,
        identifier: `map[customer_id:${customerId} username:${email}]`,
        method: "password",
        csrf_token: csrfToken
      }
    });

    if (response.data) {
      // Get jwt and set session info in localstorage
      await refreshOrySession();
      // Only set the auth mechanism if the user's registration is complete.
      setAuthMechanism("password");
      return { userId: response.data.session.identity?.id };
    }
  } catch (error: unknown) {
    if (typeof error === "object" && error !== null && "response" in error) {
      const typedError = error as {
        response?: {
          data: {
            ui?: {
              messages: { id: number; text: string; type: string }[];
            };
          };
        };
      };

      const errorMessages = typedError?.response?.data?.ui?.messages.filter((msg: any) => msg?.type === "error");

      if (errorMessages && errorMessages.length > 0) {
        throw new CustomError(errorMessages[0].text, errorMessages[0].id);
      }
    }
    debug("loginWithOry failure", error);
    throw error;
  }
};

export const loginWithPassword = async ({
  PublicAdminService,
  password,
  email,
  customerId
}: {
  PublicAdminService: PublicAdminServicePromiseClient;
  password: string;
  email: string;
  customerId?: string;
}) => {
  try {
    const {
      customerId: customerIdNumeric,
      region: awsRegion,
      userPoolId,
      userPoolWebClientId
    } = await GetCustomerLoginAttribs(PublicAdminService, customerId, email);

    const isOryAuth = userPoolId.startsWith(ORY_POOL_PREFIX);

    if (isOryAuth) {
      const { flowId, csrfToken } = await createOryLoginFlow();

      if (!flowId || !csrfToken) {
        throw new Error(`failed to create login flow, missing flowId: ${csrfToken} or csrfToken: ${flowId}`);
      }

      const loginResponse = await loginWithOry(email.toLowerCase(), password, customerIdNumeric, flowId, csrfToken);

      return {
        customerId: customerIdNumeric.toFixed(0),
        userId: loginResponse?.userId
      };
    }

    try {
      configureAmplify({
        awsRegion,
        userPoolId,
        userPoolWebClientId
      });
    } catch (e) {
      debug("configureAmplify failure", e);
      throw e;
    }

    let user;
    try {
      // We store the username in lowercase, so we need to convert it to lowercase here.
      // Note that email is equal to username here.
      user = await Auth.signIn(email.toLowerCase(), password);
    } catch (e) {
      debug("Auth.signIn failure", e);
      throw e;
    }

    if (user.challengeName !== "NEW_PASSWORD_REQUIRED") {
      // Only set the auth mechanism if the user's registration is complete.
      setAuthMechanism("password");
    }

    return { user, customerId: customerIdNumeric.toFixed(0) };
  } catch (e) {
    debug("loginWithPassword failure", e);
    throw e;
  }
};

export const confirmSignupWithPassword = async (
  PublicAdminService: PublicAdminServicePromiseClient,
  usernameOrEmail: string,
  password: string,
  newPassword: string,
  customerId: string
) => {
  try {
    const { user } = await loginWithPassword({
      PublicAdminService,
      password,
      customerId,
      email: usernameOrEmail
    });

    if (user && user.challengeName === "NEW_PASSWORD_REQUIRED") {
      await Auth.completeNewPassword(user, newPassword);
    }
  } catch (e) {
    debug(e);
  }
};

export const recoverPassword = (username: string, awsConfig: AwsConfig) => {
  configureAmplify(awsConfig);
  return Auth.forgotPassword(username);
};

export const resetForgottenPassword = (
  username: string,
  recoveryCode: string,
  newPassword: string,
  awsConfig: AwsConfig
) => {
  configureAmplify(awsConfig);
  return Auth.forgotPasswordSubmit(username, recoveryCode, newPassword);
};
