import { useState, lazy, Suspense } from "react";
import classNames from "classnames";
import { Routes, Route, Navigate, Outlet, Link, useLocation } from "react-router-dom";
import { AnalyticsProvider } from "use-analytics";
import { FocusStyleManager } from "@blueprintjs/core";
import { Elements } from "@stripe/react-stripe-js";
import AppHeader from "./views/common/appHeader/AppHeader";
import { AnalyticsContextProvider } from "./contexts/AnalyticsContext";
import { UserContextProvider, useUserContext } from "./contexts/UserContext";
import { UpgradeContextProvider } from "./contexts/UpgradeContext";
import { UploadContextProvider } from "./contexts/UploadContext";
import { JobsContextProvider } from "./contexts/JobsContext";
import { CorpusHistoryContextProvider } from "./contexts/CorpusHistoryContext";
import { ApiContextProvider } from "./contexts/ApiContext";
import { ConfigContextProvider, useConfigContext } from "./contexts/ConfigContext";
import { PlanContextProvider } from "./contexts/PlanContext";
import { NotificationsContextProvider } from "./contexts/NotificationsContext";
import { LayoutContextProvider, useLayoutContext } from "./contexts/LayoutContext";
import { AUTH_PROVIDER, stripePromise } from "./backendConfig";
import {
  LinkProps,
  VuiAppContent,
  VuiAppLayout,
  VuiContextProvider,
  VuiFlexContainer,
  VuiSpacer,
  VuiSpinner,
  VuiTitle
} from "@vectara/vectara-ui";
import { PaymentContextProvider } from "./contexts/PaymentContext";
import { UsageContextProvider } from "./contexts/UsageContext";
import { analytics } from "./contexts/utils/analytics";
import { useInitializeApp } from "./useInitializeApp";
import { AccountSizeContextProvider } from "./contexts/AccountSizeContext";
import { GetStartedContextProvider } from "./contexts/GetStartedContext";
import { ViewLoading } from "./views/common/ViewLoading";
import { RequestPasswordResetPage } from "./views/userAuth/changePassword/RequestPasswordResetPage";
import { SetNewPasswordPage } from "./views/userAuth/changePassword/SetNewPasswordPage";
import "@blueprintjs/core/lib/css/blueprint.css";
import "./app.scss";
import { AnnouncementsContextProvider } from "./contexts/AnnouncementsContext";
import SubmitOryRecoveryCode from "./views/userAuth/changePassword/SubmitOryRecoveryCode";
import SetNewOryPassword from "./views/userAuth/changePassword/SetNewOryPassword";
import { IntlProvider, ThemeProvider } from "@ory/elements";
import { AppNav } from "./views/common/appNav/AppNav";
import { CheckEmailToResetPasswordPage } from "./views/userAuth/changePassword/CheckEmailToResetPasswordPage";
import { CORPORA_PATH } from "./utils/paths";
import { CorporaContextProvider } from "./views/corpus/CorporaContext";
import { SurveyContextProvider, useSurveyContext } from "./contexts/SurveyContext";
import { SURVEY_PATH } from "./constants";
import { OnboardingSurvey } from "./views/survey/OnboardingSurvey";

// Only show a focus outline around elements when tabbing through the UI.
FocusStyleManager.onlyShowFocusOnTabs();

// Logged out UX.
const SignupPage = lazy(() => import("./views/userAuth/signupPage/SignupPage"));
const SignupAwsPage = lazy(() => import("./views/userAuth/signupPage/SignupAwsPage"));
const LoginPage = lazy(() => import("./views/userAuth/loginPage/LoginPage"));
const VerifyEmailPage = lazy(() => import("./views/userAuth/signupPage/VerifyEmailPage"));
const AcceptInvitationPage = lazy(() => import("./views/userAuth/changePassword/AcceptInvitationPage"));

// Logged in UX.
const Console = lazy(() => import("./views/Console"));

const LoggedOutAppLayout = () => (
  <VuiAppLayout full>
    <VuiAppContent padding="xl" fullWidth className="loggedOutContainer">
      <Outlet />
    </VuiAppContent>
  </VuiAppLayout>
);

const LoggedInAppLayout = () => {
  const { isSurveyActive } = useSurveyContext();
  const { isNavOpen, isNavPinnedOpen, setIsNavOpen } = useLayoutContext();

  const classes = classNames("loggedInAppLayout", {
    "loggedInAppLayout-hasNav": isNavPinnedOpen,
    "loggedInAppLayout-isWizardActive": isSurveyActive
  });

  return (
    <>
      <AppNav />
      <div
        className={classes}
        onMouseMove={() => {
          if (!isNavOpen || isNavPinnedOpen) return;
          setIsNavOpen(false);
        }}
      >
        <AppHeader />
        <Outlet />
      </div>
    </>
  );
};

const AppRoutes = () => {
  const { hasInitiallyAuthenticated, isAuthenticating, isAuthenticated } = useUserContext();
  const { isLoadingConfig } = useConfigContext();
  const { isSurveyActive } = useSurveyContext();

  // Hold upload and search state for multiple corpora.
  // TODO: Move to a context.
  const [corporaStates, setCorporaStates] = useState({});

  // This is the main entry point into the app.
  useInitializeApp();

  if (!hasInitiallyAuthenticated || isAuthenticating || isLoadingConfig) {
    return (
      <VuiFlexContainer className="authLoader" direction="column" justifyContent="center" alignItems="center">
        <VuiSpinner size="l" />
        <VuiSpacer size="l" />
        <VuiTitle size="xs">
          <h1>Loading Vectara</h1>
        </VuiTitle>
      </VuiFlexContainer>
    );
  }

  if (!isAuthenticated) {
    return (
      <ThemeProvider themeOverrides={{}}>
        <IntlProvider>
          <Routes>
            {/* Ory Routes */}
            {/* Moved Ory Routes out of LoggedOutAppLayout for styling purpose */}
            {AUTH_PROVIDER === "ory" && <Route path="/submitRecoveryCode" element={<SubmitOryRecoveryCode />} />}
            {AUTH_PROVIDER === "ory" && <Route path="/setNewOryPassword" element={<SetNewOryPassword />} />}

            <Route element={<LoggedOutAppLayout />}>
              <Route path="/requestResetPassword" element={<RequestPasswordResetPage />} />
              <Route path="/setNewPassword" element={<SetNewPasswordPage />} />
              <Route path="/checkEmailToResetPassword" element={<CheckEmailToResetPasswordPage />} />
              {/* Maintain backwards compatibility with https://bitbucket.org/zir-ai/platform/src/a0962c37cb527f0641568130803794cda084eb35/admin/sycamore/src/main/java/com/vectara/admin/actions/RegisterCustomer.java?at=master#lines-322 */}
              <Route path="/forgotPassword" element={<SetNewPasswordPage />} />
              <Route
                path="/acceptInvitation"
                element={
                  <Suspense fallback={<ViewLoading />}>
                    <AcceptInvitationPage />
                  </Suspense>
                }
              />
              <Route
                path="/login"
                element={
                  <Suspense fallback={<ViewLoading />}>
                    <LoginPage />
                  </Suspense>
                }
              />
              {/* Split between AWS and regular signup to create two distinct flows. */}
              <Route
                path="/signup"
                element={
                  <Suspense fallback={<ViewLoading />}>
                    <SignupPage />
                  </Suspense>
                }
              />
              <Route
                path="/signupAws"
                element={
                  <Suspense fallback={<ViewLoading />}>
                    <SignupAwsPage />
                  </Suspense>
                }
              />
              <Route
                path="/validate-email"
                element={
                  <Suspense fallback={<ViewLoading />}>
                    <VerifyEmailPage />
                  </Suspense>
                }
              />

              <Route path="*" element={<Navigate replace to="/login" />} />
            </Route>
          </Routes>
        </IntlProvider>
      </ThemeProvider>
    );
  }

  // This is where we want to redirect folks to after they login,
  // regardless of auth mechanism. Place this after the initialization
  // guards above, to avoid any potential race condition between showing
  // the overview page and the walkthrough.
  const LOGIN_LANDING_PAGE = isSurveyActive ? `/console/${SURVEY_PATH}` : `/console/${CORPORA_PATH}`;

  return (
    <PlanContextProvider>
      <UpgradeContextProvider>
        <PaymentContextProvider>
          <UsageContextProvider>
            <AccountSizeContextProvider>
              <JobsContextProvider>
                <CorpusHistoryContextProvider>
                  <UploadContextProvider corporaStates={corporaStates} setCorporaStates={setCorporaStates}>
                    <GetStartedContextProvider>
                      <AnnouncementsContextProvider>
                        <CorporaContextProvider>
                          <Routes>
                            <Route element={<LoggedInAppLayout />}>
                              <Route
                                path={`/console/${SURVEY_PATH}`}
                                element={
                                  <Suspense
                                    fallback={
                                      <VuiAppLayout>
                                        <ViewLoading />
                                      </VuiAppLayout>
                                    }
                                  >
                                    <OnboardingSurvey />
                                  </Suspense>
                                }
                              />

                              <Route path="/console/" element={<Navigate replace to={LOGIN_LANDING_PAGE} />} />

                              <Route
                                path="/console/*"
                                element={
                                  isSurveyActive ? (
                                    <Navigate replace to={LOGIN_LANDING_PAGE} />
                                  ) : (
                                    <Suspense fallback={<ViewLoading />}>
                                      <Console setCorporaStates={setCorporaStates} corporaStates={corporaStates} />
                                    </Suspense>
                                  )
                                }
                              />

                              <Route
                                path="*"
                                // Include the query string in the redirect so we preserve the awsToken if the user
                                // comes here from AWS Marketplace while already logged in.
                                element={<Navigate replace to={`${LOGIN_LANDING_PAGE}${window.location.search}`} />}
                              />
                            </Route>
                          </Routes>
                        </CorporaContextProvider>
                      </AnnouncementsContextProvider>
                    </GetStartedContextProvider>
                  </UploadContextProvider>
                </CorpusHistoryContextProvider>
              </JobsContextProvider>
            </AccountSizeContextProvider>
          </UsageContextProvider>
        </PaymentContextProvider>
      </UpgradeContextProvider>
    </PlanContextProvider>
  );
};

export const App = () => {
  const location = useLocation();

  const linkProvider = (linkConfig: LinkProps) => {
    const { className, href, onClick, children, ...rest } = linkConfig;

    return (
      <Link className={className} to={href ?? ""} onClick={onClick} {...rest}>
        {children}
      </Link>
    );
  };

  const pathProvider = () => {
    return location.pathname;
  };

  return (
    <Elements stripe={stripePromise}>
      <VuiContextProvider linkProvider={linkProvider} pathProvider={pathProvider}>
        <ApiContextProvider>
          <AnalyticsContextProvider>
            <UserContextProvider>
              <NotificationsContextProvider>
                <LayoutContextProvider>
                  <ConfigContextProvider>
                    <AnalyticsProvider instance={analytics}>
                      <SurveyContextProvider>
                        {/* This prevents this error when switching back and forth between */}
                        {/* the signnup and login pages: https://github.com/facebook/react/issues/25629 */}
                        <Suspense
                          fallback={
                            <VuiAppLayout>
                              <ViewLoading />
                            </VuiAppLayout>
                          }
                        >
                          <AppRoutes />
                        </Suspense>
                      </SurveyContextProvider>
                    </AnalyticsProvider>
                  </ConfigContextProvider>
                </LayoutContextProvider>
              </NotificationsContextProvider>
            </UserContextProvider>
          </AnalyticsContextProvider>
        </ApiContextProvider>
      </VuiContextProvider>
    </Elements>
  );
};
