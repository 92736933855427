// source: admin/admin_signup.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var currency_pb = require('../currency_pb.js');
goog.object.extend(proto, currency_pb);
var status_pb = require('../status_pb.js');
goog.object.extend(proto, status_pb);
var admin_admin_country_pb = require('../admin/admin_country_pb.js');
goog.object.extend(proto, admin_admin_country_pb);
goog.exportSymbol('proto.com.vectara.admin.ChangeCustomerPlanRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ChangeCustomerPlanResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.CheckEmailValidationStatusRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.CheckEmailValidationStatusResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.DeleteCustomerRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.DeleteCustomerResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.EmailValidationStatus', null, global);
goog.exportSymbol('proto.com.vectara.admin.PlanName', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadCurrentIdForPlanRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadCurrentIdForPlanResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadCustomerPlanDetailsRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadCustomerPlanDetailsResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.RegisterCustomerRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.RegisterCustomerResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.SocialLoginRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.SocialLoginResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.UserAccount', null, global);
goog.exportSymbol('proto.com.vectara.admin.ValidateEmailRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ValidateEmailResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.SocialLoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.SocialLoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.SocialLoginRequest.displayName = 'proto.com.vectara.admin.SocialLoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.SocialLoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.SocialLoginResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.SocialLoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.SocialLoginResponse.displayName = 'proto.com.vectara.admin.SocialLoginResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.UserAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.UserAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.UserAccount.displayName = 'proto.com.vectara.admin.UserAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.RegisterCustomerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.RegisterCustomerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.RegisterCustomerRequest.displayName = 'proto.com.vectara.admin.RegisterCustomerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.RegisterCustomerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.com.vectara.admin.RegisterCustomerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.RegisterCustomerResponse.displayName = 'proto.com.vectara.admin.RegisterCustomerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ValidateEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ValidateEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ValidateEmailRequest.displayName = 'proto.com.vectara.admin.ValidateEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ValidateEmailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.com.vectara.admin.ValidateEmailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ValidateEmailResponse.displayName = 'proto.com.vectara.admin.ValidateEmailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.CheckEmailValidationStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.CheckEmailValidationStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.CheckEmailValidationStatusRequest.displayName = 'proto.com.vectara.admin.CheckEmailValidationStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.CheckEmailValidationStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.com.vectara.admin.CheckEmailValidationStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.CheckEmailValidationStatusResponse.displayName = 'proto.com.vectara.admin.CheckEmailValidationStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.DeleteCustomerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.DeleteCustomerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.DeleteCustomerRequest.displayName = 'proto.com.vectara.admin.DeleteCustomerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.DeleteCustomerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.com.vectara.admin.DeleteCustomerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.DeleteCustomerResponse.displayName = 'proto.com.vectara.admin.DeleteCustomerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ReadCustomerPlanDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadCustomerPlanDetailsRequest.displayName = 'proto.com.vectara.admin.ReadCustomerPlanDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.com.vectara.admin.ReadCustomerPlanDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.displayName = 'proto.com.vectara.admin.ReadCustomerPlanDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ChangeCustomerPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ChangeCustomerPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ChangeCustomerPlanRequest.displayName = 'proto.com.vectara.admin.ChangeCustomerPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ChangeCustomerPlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.com.vectara.admin.ChangeCustomerPlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ChangeCustomerPlanResponse.displayName = 'proto.com.vectara.admin.ChangeCustomerPlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadCurrentIdForPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ReadCurrentIdForPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadCurrentIdForPlanRequest.displayName = 'proto.com.vectara.admin.ReadCurrentIdForPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadCurrentIdForPlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.com.vectara.admin.ReadCurrentIdForPlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadCurrentIdForPlanResponse.displayName = 'proto.com.vectara.admin.ReadCurrentIdForPlanResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.SocialLoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.SocialLoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.SocialLoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.SocialLoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    jwt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    registration: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    awsToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.SocialLoginRequest}
 */
proto.com.vectara.admin.SocialLoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.SocialLoginRequest;
  return proto.com.vectara.admin.SocialLoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.SocialLoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.SocialLoginRequest}
 */
proto.com.vectara.admin.SocialLoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJwt(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRegistration(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAwsToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.SocialLoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.SocialLoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.SocialLoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.SocialLoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJwt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegistration();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAwsToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string jwt = 1;
 * @return {string}
 */
proto.com.vectara.admin.SocialLoginRequest.prototype.getJwt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.SocialLoginRequest} returns this
 */
proto.com.vectara.admin.SocialLoginRequest.prototype.setJwt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool registration = 2;
 * @return {boolean}
 */
proto.com.vectara.admin.SocialLoginRequest.prototype.getRegistration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.SocialLoginRequest} returns this
 */
proto.com.vectara.admin.SocialLoginRequest.prototype.setRegistration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string aws_token = 3;
 * @return {string}
 */
proto.com.vectara.admin.SocialLoginRequest.prototype.getAwsToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.SocialLoginRequest} returns this
 */
proto.com.vectara.admin.SocialLoginRequest.prototype.setAwsToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.SocialLoginResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.SocialLoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.SocialLoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.SocialLoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.SocialLoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto.com.vectara.admin.UserAccount.toObject, includeInstance),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.SocialLoginResponse}
 */
proto.com.vectara.admin.SocialLoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.SocialLoginResponse;
  return proto.com.vectara.admin.SocialLoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.SocialLoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.SocialLoginResponse}
 */
proto.com.vectara.admin.SocialLoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.UserAccount;
      reader.readMessage(value,proto.com.vectara.admin.UserAccount.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.SocialLoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.SocialLoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.SocialLoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.SocialLoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.UserAccount.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserAccount accounts = 1;
 * @return {!Array<!proto.com.vectara.admin.UserAccount>}
 */
proto.com.vectara.admin.SocialLoginResponse.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.UserAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.UserAccount, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.UserAccount>} value
 * @return {!proto.com.vectara.admin.SocialLoginResponse} returns this
*/
proto.com.vectara.admin.SocialLoginResponse.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.UserAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.UserAccount}
 */
proto.com.vectara.admin.SocialLoginResponse.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.UserAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.SocialLoginResponse} returns this
 */
proto.com.vectara.admin.SocialLoginResponse.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.SocialLoginResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.SocialLoginResponse} returns this
*/
proto.com.vectara.admin.SocialLoginResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.SocialLoginResponse} returns this
 */
proto.com.vectara.admin.SocialLoginResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.SocialLoginResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.UserAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.UserAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.UserAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UserAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    org: jspb.Message.getFieldWithDefault(msg, 2, ""),
    handle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastActive: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.UserAccount}
 */
proto.com.vectara.admin.UserAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.UserAccount;
  return proto.com.vectara.admin.UserAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.UserAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.UserAccount}
 */
proto.com.vectara.admin.UserAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrg(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandle(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLastActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.UserAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.UserAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.UserAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UserAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getOrg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHandle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastActive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional uint32 customer_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.UserAccount.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.UserAccount} returns this
 */
proto.com.vectara.admin.UserAccount.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string org = 2;
 * @return {string}
 */
proto.com.vectara.admin.UserAccount.prototype.getOrg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.UserAccount} returns this
 */
proto.com.vectara.admin.UserAccount.prototype.setOrg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string handle = 3;
 * @return {string}
 */
proto.com.vectara.admin.UserAccount.prototype.getHandle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.UserAccount} returns this
 */
proto.com.vectara.admin.UserAccount.prototype.setHandle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool last_active = 4;
 * @return {boolean}
 */
proto.com.vectara.admin.UserAccount.prototype.getLastActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.UserAccount} returns this
 */
proto.com.vectara.admin.UserAccount.prototype.setLastActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.RegisterCustomerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.RegisterCustomerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.RegisterCustomerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    org: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    password: jspb.Message.getFieldWithDefault(msg, 4, ""),
    planId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    accountAlias: jspb.Message.getFieldWithDefault(msg, 7, ""),
    stripePaymentSource: jspb.Message.getFieldWithDefault(msg, 6, ""),
    emailVerificationCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    awsToken: jspb.Message.getFieldWithDefault(msg, 14, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 9, 0),
    generateCmk: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    cmkArn: jspb.Message.getFieldWithDefault(msg, 11, ""),
    maxCorpora: jspb.Message.getFieldWithDefault(msg, 12, 0),
    maxCorporaPerQuery: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.RegisterCustomerRequest}
 */
proto.com.vectara.admin.RegisterCustomerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.RegisterCustomerRequest;
  return proto.com.vectara.admin.RegisterCustomerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.RegisterCustomerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.RegisterCustomerRequest}
 */
proto.com.vectara.admin.RegisterCustomerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrg(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountAlias(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripePaymentSource(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailVerificationCode(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAwsToken(value);
      break;
    case 9:
      var value = /** @type {!proto.com.vectara.admin.CountryCode} */ (reader.readEnum());
      msg.setCountryCode(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGenerateCmk(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCmkArn(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxCorpora(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxCorporaPerQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.RegisterCustomerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.RegisterCustomerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.RegisterCustomerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPlanId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccountAlias();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStripePaymentSource();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmailVerificationCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAwsToken();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCountryCode();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getGenerateCmk();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCmkArn();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getMaxCorpora();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getMaxCorporaPerQuery();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.RegisterCustomerRequest} returns this
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string org = 2;
 * @return {string}
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.getOrg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.RegisterCustomerRequest} returns this
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.setOrg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.RegisterCustomerRequest} returns this
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string password = 4;
 * @return {string}
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.RegisterCustomerRequest} returns this
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string plan_id = 5;
 * @return {string}
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.getPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.RegisterCustomerRequest} returns this
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string account_alias = 7;
 * @return {string}
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.getAccountAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.RegisterCustomerRequest} returns this
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.setAccountAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string stripe_payment_source = 6;
 * @return {string}
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.getStripePaymentSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.RegisterCustomerRequest} returns this
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.setStripePaymentSource = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string email_verification_code = 8;
 * @return {string}
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.getEmailVerificationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.RegisterCustomerRequest} returns this
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.setEmailVerificationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string aws_token = 14;
 * @return {string}
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.getAwsToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.RegisterCustomerRequest} returns this
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.setAwsToken = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional CountryCode country_code = 9;
 * @return {!proto.com.vectara.admin.CountryCode}
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.getCountryCode = function() {
  return /** @type {!proto.com.vectara.admin.CountryCode} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.com.vectara.admin.CountryCode} value
 * @return {!proto.com.vectara.admin.RegisterCustomerRequest} returns this
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional bool generate_cmk = 10;
 * @return {boolean}
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.getGenerateCmk = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.RegisterCustomerRequest} returns this
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.setGenerateCmk = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string cmk_arn = 11;
 * @return {string}
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.getCmkArn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.RegisterCustomerRequest} returns this
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.setCmkArn = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional uint32 max_corpora = 12;
 * @return {number}
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.getMaxCorpora = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.RegisterCustomerRequest} returns this
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.setMaxCorpora = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 max_corpora_per_query = 13;
 * @return {number}
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.getMaxCorporaPerQuery = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.RegisterCustomerRequest} returns this
 */
proto.com.vectara.admin.RegisterCustomerRequest.prototype.setMaxCorporaPerQuery = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.RegisterCustomerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.RegisterCustomerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.RegisterCustomerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.RegisterCustomerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cmkArn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.RegisterCustomerResponse}
 */
proto.com.vectara.admin.RegisterCustomerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.RegisterCustomerResponse;
  return proto.com.vectara.admin.RegisterCustomerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.RegisterCustomerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.RegisterCustomerResponse}
 */
proto.com.vectara.admin.RegisterCustomerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCmkArn(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.RegisterCustomerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.RegisterCustomerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.RegisterCustomerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.RegisterCustomerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCmkArn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 customer_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.RegisterCustomerResponse.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.RegisterCustomerResponse} returns this
 */
proto.com.vectara.admin.RegisterCustomerResponse.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string cmk_arn = 2;
 * @return {string}
 */
proto.com.vectara.admin.RegisterCustomerResponse.prototype.getCmkArn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.RegisterCustomerResponse} returns this
 */
proto.com.vectara.admin.RegisterCustomerResponse.prototype.setCmkArn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.RegisterCustomerResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.RegisterCustomerResponse} returns this
*/
proto.com.vectara.admin.RegisterCustomerResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.RegisterCustomerResponse} returns this
 */
proto.com.vectara.admin.RegisterCustomerResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.RegisterCustomerResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ValidateEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ValidateEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ValidateEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ValidateEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    registrationRequest: (f = msg.getRegistrationRequest()) && proto.com.vectara.admin.RegisterCustomerRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ValidateEmailRequest}
 */
proto.com.vectara.admin.ValidateEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ValidateEmailRequest;
  return proto.com.vectara.admin.ValidateEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ValidateEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ValidateEmailRequest}
 */
proto.com.vectara.admin.ValidateEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.RegisterCustomerRequest;
      reader.readMessage(value,proto.com.vectara.admin.RegisterCustomerRequest.deserializeBinaryFromReader);
      msg.setRegistrationRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ValidateEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ValidateEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ValidateEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ValidateEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistrationRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.vectara.admin.RegisterCustomerRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional RegisterCustomerRequest registration_request = 1;
 * @return {?proto.com.vectara.admin.RegisterCustomerRequest}
 */
proto.com.vectara.admin.ValidateEmailRequest.prototype.getRegistrationRequest = function() {
  return /** @type{?proto.com.vectara.admin.RegisterCustomerRequest} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.RegisterCustomerRequest, 1));
};


/**
 * @param {?proto.com.vectara.admin.RegisterCustomerRequest|undefined} value
 * @return {!proto.com.vectara.admin.ValidateEmailRequest} returns this
*/
proto.com.vectara.admin.ValidateEmailRequest.prototype.setRegistrationRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ValidateEmailRequest} returns this
 */
proto.com.vectara.admin.ValidateEmailRequest.prototype.clearRegistrationRequest = function() {
  return this.setRegistrationRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ValidateEmailRequest.prototype.hasRegistrationRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ValidateEmailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ValidateEmailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ValidateEmailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ValidateEmailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ValidateEmailResponse}
 */
proto.com.vectara.admin.ValidateEmailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ValidateEmailResponse;
  return proto.com.vectara.admin.ValidateEmailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ValidateEmailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ValidateEmailResponse}
 */
proto.com.vectara.admin.ValidateEmailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailToken(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ValidateEmailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ValidateEmailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ValidateEmailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ValidateEmailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional string email_token = 1;
 * @return {string}
 */
proto.com.vectara.admin.ValidateEmailResponse.prototype.getEmailToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ValidateEmailResponse} returns this
 */
proto.com.vectara.admin.ValidateEmailResponse.prototype.setEmailToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ValidateEmailResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ValidateEmailResponse} returns this
*/
proto.com.vectara.admin.ValidateEmailResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ValidateEmailResponse} returns this
 */
proto.com.vectara.admin.ValidateEmailResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ValidateEmailResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.CheckEmailValidationStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.CheckEmailValidationStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.CheckEmailValidationStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CheckEmailValidationStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.CheckEmailValidationStatusRequest}
 */
proto.com.vectara.admin.CheckEmailValidationStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.CheckEmailValidationStatusRequest;
  return proto.com.vectara.admin.CheckEmailValidationStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.CheckEmailValidationStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.CheckEmailValidationStatusRequest}
 */
proto.com.vectara.admin.CheckEmailValidationStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.CheckEmailValidationStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.CheckEmailValidationStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.CheckEmailValidationStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CheckEmailValidationStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email_token = 1;
 * @return {string}
 */
proto.com.vectara.admin.CheckEmailValidationStatusRequest.prototype.getEmailToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.CheckEmailValidationStatusRequest} returns this
 */
proto.com.vectara.admin.CheckEmailValidationStatusRequest.prototype.setEmailToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.CheckEmailValidationStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.CheckEmailValidationStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.CheckEmailValidationStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CheckEmailValidationStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    validationStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
    customerId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    email: jspb.Message.getFieldWithDefault(msg, 15, ""),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.CheckEmailValidationStatusResponse}
 */
proto.com.vectara.admin.CheckEmailValidationStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.CheckEmailValidationStatusResponse;
  return proto.com.vectara.admin.CheckEmailValidationStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.CheckEmailValidationStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.CheckEmailValidationStatusResponse}
 */
proto.com.vectara.admin.CheckEmailValidationStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {!proto.com.vectara.admin.EmailValidationStatus} */ (reader.readEnum());
      msg.setValidationStatus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCustomerId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.CheckEmailValidationStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.CheckEmailValidationStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.CheckEmailValidationStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CheckEmailValidationStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValidationStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional EmailValidationStatus validation_status = 5;
 * @return {!proto.com.vectara.admin.EmailValidationStatus}
 */
proto.com.vectara.admin.CheckEmailValidationStatusResponse.prototype.getValidationStatus = function() {
  return /** @type {!proto.com.vectara.admin.EmailValidationStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.com.vectara.admin.EmailValidationStatus} value
 * @return {!proto.com.vectara.admin.CheckEmailValidationStatusResponse} returns this
 */
proto.com.vectara.admin.CheckEmailValidationStatusResponse.prototype.setValidationStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional uint32 customer_id = 10;
 * @return {number}
 */
proto.com.vectara.admin.CheckEmailValidationStatusResponse.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.CheckEmailValidationStatusResponse} returns this
 */
proto.com.vectara.admin.CheckEmailValidationStatusResponse.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string email = 15;
 * @return {string}
 */
proto.com.vectara.admin.CheckEmailValidationStatusResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.CheckEmailValidationStatusResponse} returns this
 */
proto.com.vectara.admin.CheckEmailValidationStatusResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.CheckEmailValidationStatusResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.CheckEmailValidationStatusResponse} returns this
*/
proto.com.vectara.admin.CheckEmailValidationStatusResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.CheckEmailValidationStatusResponse} returns this
 */
proto.com.vectara.admin.CheckEmailValidationStatusResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.CheckEmailValidationStatusResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.DeleteCustomerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.DeleteCustomerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.DeleteCustomerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.DeleteCustomerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.DeleteCustomerRequest}
 */
proto.com.vectara.admin.DeleteCustomerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.DeleteCustomerRequest;
  return proto.com.vectara.admin.DeleteCustomerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.DeleteCustomerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.DeleteCustomerRequest}
 */
proto.com.vectara.admin.DeleteCustomerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.DeleteCustomerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.DeleteCustomerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.DeleteCustomerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.DeleteCustomerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.DeleteCustomerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.DeleteCustomerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.DeleteCustomerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.DeleteCustomerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.DeleteCustomerResponse}
 */
proto.com.vectara.admin.DeleteCustomerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.DeleteCustomerResponse;
  return proto.com.vectara.admin.DeleteCustomerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.DeleteCustomerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.DeleteCustomerResponse}
 */
proto.com.vectara.admin.DeleteCustomerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.DeleteCustomerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.DeleteCustomerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.DeleteCustomerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.DeleteCustomerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.DeleteCustomerResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.DeleteCustomerResponse} returns this
*/
proto.com.vectara.admin.DeleteCustomerResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.DeleteCustomerResponse} returns this
 */
proto.com.vectara.admin.DeleteCustomerResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.DeleteCustomerResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadCustomerPlanDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadCustomerPlanDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsRequest}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadCustomerPlanDetailsRequest;
  return proto.com.vectara.admin.ReadCustomerPlanDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadCustomerPlanDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsRequest}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadCustomerPlanDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadCustomerPlanDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsRequest} returns this
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 5, 0),
    customerPlanId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    name: jspb.Message.getFieldWithDefault(msg, 15, ""),
    description: jspb.Message.getFieldWithDefault(msg, 20, ""),
    pricingPlanJson: jspb.Message.getFieldWithDefault(msg, 25, ""),
    initialCredit: (f = msg.getInitialCredit()) && currency_pb.CurrencyAmount.toObject(includeInstance, f),
    amountDue: (f = msg.getAmountDue()) && currency_pb.CurrencyAmount.toObject(includeInstance, f),
    planName: jspb.Message.getFieldWithDefault(msg, 40, 0),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadCustomerPlanDetailsResponse;
  return proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerPlanId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setPricingPlanJson(value);
      break;
    case 30:
      var value = new currency_pb.CurrencyAmount;
      reader.readMessage(value,currency_pb.CurrencyAmount.deserializeBinaryFromReader);
      msg.setInitialCredit(value);
      break;
    case 35:
      var value = new currency_pb.CurrencyAmount;
      reader.readMessage(value,currency_pb.CurrencyAmount.deserializeBinaryFromReader);
      msg.setAmountDue(value);
      break;
    case 40:
      var value = /** @type {!proto.com.vectara.admin.PlanName} */ (reader.readEnum());
      msg.setPlanName(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getCustomerPlanId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPricingPlanJson();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getInitialCredit();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      currency_pb.CurrencyAmount.serializeBinaryToWriter
    );
  }
  f = message.getAmountDue();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      currency_pb.CurrencyAmount.serializeBinaryToWriter
    );
  }
  f = message.getPlanName();
  if (f !== 0.0) {
    writer.writeEnum(
      40,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 5;
 * @return {number}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse} returns this
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string customer_plan_id = 10;
 * @return {string}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.getCustomerPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse} returns this
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.setCustomerPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string name = 15;
 * @return {string}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse} returns this
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string description = 20;
 * @return {string}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse} returns this
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string pricing_plan_json = 25;
 * @return {string}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.getPricingPlanJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse} returns this
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.setPricingPlanJson = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional com.vectara.currency.CurrencyAmount initial_credit = 30;
 * @return {?proto.com.vectara.currency.CurrencyAmount}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.getInitialCredit = function() {
  return /** @type{?proto.com.vectara.currency.CurrencyAmount} */ (
    jspb.Message.getWrapperField(this, currency_pb.CurrencyAmount, 30));
};


/**
 * @param {?proto.com.vectara.currency.CurrencyAmount|undefined} value
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse} returns this
*/
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.setInitialCredit = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse} returns this
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.clearInitialCredit = function() {
  return this.setInitialCredit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.hasInitialCredit = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional com.vectara.currency.CurrencyAmount amount_due = 35;
 * @return {?proto.com.vectara.currency.CurrencyAmount}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.getAmountDue = function() {
  return /** @type{?proto.com.vectara.currency.CurrencyAmount} */ (
    jspb.Message.getWrapperField(this, currency_pb.CurrencyAmount, 35));
};


/**
 * @param {?proto.com.vectara.currency.CurrencyAmount|undefined} value
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse} returns this
*/
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.setAmountDue = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse} returns this
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.clearAmountDue = function() {
  return this.setAmountDue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.hasAmountDue = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional PlanName plan_name = 40;
 * @return {!proto.com.vectara.admin.PlanName}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.getPlanName = function() {
  return /** @type {!proto.com.vectara.admin.PlanName} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {!proto.com.vectara.admin.PlanName} value
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse} returns this
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.setPlanName = function(value) {
  return jspb.Message.setProto3EnumField(this, 40, value);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse} returns this
*/
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCustomerPlanDetailsResponse} returns this
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCustomerPlanDetailsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ChangeCustomerPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ChangeCustomerPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ChangeCustomerPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ChangeCustomerPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    newPlanId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    planEffectiveTs: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ChangeCustomerPlanRequest}
 */
proto.com.vectara.admin.ChangeCustomerPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ChangeCustomerPlanRequest;
  return proto.com.vectara.admin.ChangeCustomerPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ChangeCustomerPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ChangeCustomerPlanRequest}
 */
proto.com.vectara.admin.ChangeCustomerPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPlanId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlanEffectiveTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ChangeCustomerPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ChangeCustomerPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ChangeCustomerPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ChangeCustomerPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewPlanId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPlanEffectiveTs();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional string new_plan_id = 5;
 * @return {string}
 */
proto.com.vectara.admin.ChangeCustomerPlanRequest.prototype.getNewPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ChangeCustomerPlanRequest} returns this
 */
proto.com.vectara.admin.ChangeCustomerPlanRequest.prototype.setNewPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 plan_effective_ts = 10;
 * @return {number}
 */
proto.com.vectara.admin.ChangeCustomerPlanRequest.prototype.getPlanEffectiveTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ChangeCustomerPlanRequest} returns this
 */
proto.com.vectara.admin.ChangeCustomerPlanRequest.prototype.setPlanEffectiveTs = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ChangeCustomerPlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ChangeCustomerPlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ChangeCustomerPlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ChangeCustomerPlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    newPlanId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    planEffectiveTs: jspb.Message.getFieldWithDefault(msg, 10, 0),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ChangeCustomerPlanResponse}
 */
proto.com.vectara.admin.ChangeCustomerPlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ChangeCustomerPlanResponse;
  return proto.com.vectara.admin.ChangeCustomerPlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ChangeCustomerPlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ChangeCustomerPlanResponse}
 */
proto.com.vectara.admin.ChangeCustomerPlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPlanId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlanEffectiveTs(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ChangeCustomerPlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ChangeCustomerPlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ChangeCustomerPlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ChangeCustomerPlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewPlanId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPlanEffectiveTs();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional string new_plan_id = 5;
 * @return {string}
 */
proto.com.vectara.admin.ChangeCustomerPlanResponse.prototype.getNewPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ChangeCustomerPlanResponse} returns this
 */
proto.com.vectara.admin.ChangeCustomerPlanResponse.prototype.setNewPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 plan_effective_ts = 10;
 * @return {number}
 */
proto.com.vectara.admin.ChangeCustomerPlanResponse.prototype.getPlanEffectiveTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ChangeCustomerPlanResponse} returns this
 */
proto.com.vectara.admin.ChangeCustomerPlanResponse.prototype.setPlanEffectiveTs = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ChangeCustomerPlanResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ChangeCustomerPlanResponse} returns this
*/
proto.com.vectara.admin.ChangeCustomerPlanResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ChangeCustomerPlanResponse} returns this
 */
proto.com.vectara.admin.ChangeCustomerPlanResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ChangeCustomerPlanResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadCurrentIdForPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadCurrentIdForPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadCurrentIdForPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCurrentIdForPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    planName: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadCurrentIdForPlanRequest}
 */
proto.com.vectara.admin.ReadCurrentIdForPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadCurrentIdForPlanRequest;
  return proto.com.vectara.admin.ReadCurrentIdForPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadCurrentIdForPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadCurrentIdForPlanRequest}
 */
proto.com.vectara.admin.ReadCurrentIdForPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {!proto.com.vectara.admin.PlanName} */ (reader.readEnum());
      msg.setPlanName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadCurrentIdForPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadCurrentIdForPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadCurrentIdForPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCurrentIdForPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanName();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional PlanName plan_name = 5;
 * @return {!proto.com.vectara.admin.PlanName}
 */
proto.com.vectara.admin.ReadCurrentIdForPlanRequest.prototype.getPlanName = function() {
  return /** @type {!proto.com.vectara.admin.PlanName} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.com.vectara.admin.PlanName} value
 * @return {!proto.com.vectara.admin.ReadCurrentIdForPlanRequest} returns this
 */
proto.com.vectara.admin.ReadCurrentIdForPlanRequest.prototype.setPlanName = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadCurrentIdForPlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadCurrentIdForPlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadCurrentIdForPlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCurrentIdForPlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    planId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadCurrentIdForPlanResponse}
 */
proto.com.vectara.admin.ReadCurrentIdForPlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadCurrentIdForPlanResponse;
  return proto.com.vectara.admin.ReadCurrentIdForPlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadCurrentIdForPlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadCurrentIdForPlanResponse}
 */
proto.com.vectara.admin.ReadCurrentIdForPlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanId(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadCurrentIdForPlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadCurrentIdForPlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadCurrentIdForPlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadCurrentIdForPlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional string plan_id = 5;
 * @return {string}
 */
proto.com.vectara.admin.ReadCurrentIdForPlanResponse.prototype.getPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ReadCurrentIdForPlanResponse} returns this
 */
proto.com.vectara.admin.ReadCurrentIdForPlanResponse.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReadCurrentIdForPlanResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReadCurrentIdForPlanResponse} returns this
*/
proto.com.vectara.admin.ReadCurrentIdForPlanResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadCurrentIdForPlanResponse} returns this
 */
proto.com.vectara.admin.ReadCurrentIdForPlanResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadCurrentIdForPlanResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};


/**
 * @enum {number}
 */
proto.com.vectara.admin.EmailValidationStatus = {
  EMAIL_VALIDATION_STATUS__UNDEFINED: 0,
  EMAIL_VALIDATION_STATUS__UNVALIDATED: 1,
  EMAIL_VALIDATION_STATUS__EXPIRED: 2,
  EMAIL_VALIDATION_STATUS__VALIDATED: 3
};

/**
 * @enum {number}
 */
proto.com.vectara.admin.PlanName = {
  PLAN_NAME__UNDEFINED: 0,
  PLAN_NAME__GROWTH_FREE: 1,
  PLAN_NAME__GROWTH_CC: 2,
  PLAN_NAME__SCALE: 3,
  PLAN_NAME__CUSTOM: 4,
  PLAN_NAME__BUSINESS: 5,
  PLAN_NAME__FREE_TRIAL: 6
};

goog.object.extend(exports, proto.com.vectara.admin);
