import { AdminServicePromiseClient } from "../generated_protos/services_grpc_web_pb";
import { ListJobsRequest, ListJobsResponse } from "../generated_protos/admin/admin_job_pb";

export const ListJobs = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string,
  corpusIds: number[]
): Promise<ListJobsResponse.AsObject> => {
  const request = new ListJobsRequest();
  request.setCorpusIdList(corpusIds);
  const args = {
    "customer-id": customerId,
    authorization: "Bearer " + jwt
  };
  const response = await adminService.listJobs(request, args);
  return response.toObject();
};
